<ng-content select="pw-priority-score-dialog-header"/>
<section class="item-summery-container">
  <ng-content select="[slot=summary]"/>
</section>
<section class="form-container pw-card">
  <ng-content/>
</section>
<footer>
  <ng-content select="[slot=footer]"/>
</footer>
