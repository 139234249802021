import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DrawerHeaderUiComponent,
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerFooterComponent,
  PwDrawerHeaderComponent,
  useRegisterDrawer,
} from '@pwiz/infra/drawer';
import { ItemGlanceComponent, ItemStatsComponent } from '@pwiz/item/ui';
import { ItemSummaryService } from '@pwiz/item/summary/ui-data';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  ItemOverviewComponent,
  ItemPriorityOverviewComponent,
} from '@pwiz/item/summary/ui';
import { UiPriority } from '@pwiz/priority/ts';
import { $useSettingsSafe } from '@pwiz/settings/ui-data';
import { $useUserList } from '@pwiz/user/ui-data';
import { useItemPriorityDialog } from '@pwiz/item/feature';
import { SprintItemScheduleComponent } from '@pwiz/sprint/ui';
import { getItemIssue } from '@pwiz/item/ts';
import { PRIORITY_DIALOG_SERVICE_PROVIDER } from '@pwiz/priority/feature';
import { startWith, switchMap } from 'rxjs';
import { pwCacheData } from '@pwiz/infra/cache/ts';
import { ItemSprintSchedule } from '@pwiz/sprint/ts';

@Component({
  selector: 'pw-item-summary-drawer',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    DrawerHeaderUiComponent,
    PwDrawerContentComponent,
    PwDrawerFooterComponent,
    ItemGlanceComponent,
    ItemStatsComponent,
    ItemPriorityOverviewComponent,
    ItemOverviewComponent,
    PwDrawerHeaderComponent,
    SprintItemScheduleComponent,
  ],
  templateUrl: './item-summary-drawer.component.html',
  styleUrl: './item-summary-drawer.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ItemSummaryService, PRIORITY_DIALOG_SERVICE_PROVIDER],
})
export class ItemSummaryDrawerComponent {
  #itemSummaryService = inject(ItemSummaryService);
  #priorityChangeDialog = useItemPriorityDialog();

  $users = $useUserList();
  $settings = $useSettingsSafe();

  itemId = input.required<string>();
  itemId$ = toObservable(this.itemId);
  $item = toSignal(
    this.itemId$.pipe(
      switchMap((itemId) => this.#itemSummaryService.getItemSummary$(itemId)),
    ),
    {
      initialValue: null,
    },
  );
  $itemSchedule = toSignal(
    this.itemId$.pipe(
      switchMap((itemId) => this.#itemSummaryService.getItemSchedule(itemId)),
      startWith(pwCacheData<ItemSprintSchedule>('loading')),
    ),
    {
      requireSync: true,
    },
  );
  $team = computed(() =>
    this.$itemSchedule().data
      ? getItemIssue(this.$itemSchedule().data!)?.team || null
      : null,
  );

  $itemManualPriorityChangEvent = toSignal(
    this.itemId$.pipe(
      switchMap((itemId) =>
        this.#itemSummaryService.getItemPriorityChangeAuditEvent$(itemId),
      ),
    ),
    { initialValue: null },
  );

  constructor() {
    useRegisterDrawer('Item Summary', this.itemId);
  }

  onViewPriority({ priority }: { priority: UiPriority }) {
    const item = this.$item();
    if (!item) {
      return;
    }
    this.#itemSummaryService.openPriorityDialog(priority);
  }

  onPriorityClick() {
    this.#priorityChangeDialog(this.itemId()).subscribe();
  }

  onViewTickets() {}
}
