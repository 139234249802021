<form (ngSubmit)="onSubmit()" class="form" [formGroup]="form">
  <pw-priority-score-dialog-container>
    <pw-priority-score-dialog-header [priority]="$priority()">
      Effort Scoring
    </pw-priority-score-dialog-header>

    <pw-priority-summary
      [users]="$users()"
      [priority]="$priority()"
      [priorityLead]="$assignees().priorityLead"
      slot="summary"
    />

    <pw-priority-effort-form
      class="effort-form"
      [effortTimeMap$]="effortTimeMapByItem()"
      slot="form"
    >
      @if (isInternal()) {
        <pw-internal-item-marker
          slot="info-header"
          [type]="$isBug() ? 'bug' : 'feature'"
        />
      }
      <span>
        Item Cost Max:
        <strong><pw-currency [amount]="itemMaxCost() | pwRound" /> </strong>
      </span>
    </pw-priority-effort-form>

    <pw-priority-score-submit-container slot="footer">
      @if ($priority() | pwPriorityAutoRated) {
        <span class="danger">* Rated automatically</span>
      }
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="submit-button"
        [disabled]="!$disableSaveBtn()"
        slot="save-button"
      >
        Submit
      </button>
    </pw-priority-score-submit-container>
  </pw-priority-score-dialog-container>
</form>
