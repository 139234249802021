@switch (content.type) {
  @case ('text') {
    {{ content.text }}
  }
  @case ('hardBreak') {
    <br />
  }
  @case ('inlineCard') {
    @if (content.attrs?.url; as url) {
      <a [href]="url">{{url}}</a>
    }
  }
}
