@if (priority(); as priority) {
  <pw-label-group inLine showSeparators stretch [card]="design() === 2">
    @if ($isScored()) {
      <pw-label class="large">
        <pw-label-header>Scored by:</pw-label-header>
        <p>{{ $scoredBy() | pwNullableValue }}</p>
        <p>{{ priority.scoredDate | pwDateTime: 'shortDate' }}</p>
      </pw-label>
    }
    <pw-label class="large">
      <pw-label-header>Assignee:</pw-label-header>
      <p>{{ priority.providerAssignee?.name }}</p>
    </pw-label>
    <pw-label>
      <pw-label-header>Item Type:</pw-label-header>
      {{ priority.item.type | pwEnumToReadableString }}
    </pw-label>
    <pw-label>
      <pw-label-header>Item ID:</pw-label-header>
      <pw-item-id [item]="priority.item" mode="itemId" />
    </pw-label>
    <pw-label>
      <pw-label-header>Status:</pw-label-header>
      <p>{{ priority?.item?.status | pwEnumToReadableString }}</p>
    </pw-label>
    <pw-label>
      <pw-label-header>Created:</pw-label-header>
      <p>{{ priority.item.ticketBase.issueCreatedAt | pwDateTime: 'shortDate' }}</p>
    </pw-label>
    <pw-label>
      <pw-label-header>Board:</pw-label-header>
      <p>{{ $ticketBase()?.board?.name }}</p>
    </pw-label>
    @if ($isPrioritized()) {
      <pw-label>
        <pw-label-header>Risk:</pw-label-header>
        <pw-currency [amount]="priority.item.risk"/>
      </pw-label>
    }
  </pw-label-group>
}
