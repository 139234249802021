<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
  <pw-priority-score-dialog-container>
    <pw-priority-score-dialog-header [priority]="$priority()">
      Impact Scoring
    </pw-priority-score-dialog-header>

    <pw-item-impact-form [clients]="allClients()" [isFeature]="$isFeature()" [ratedWithNoCustomers]="$ratedWithNoCustomers()" [initialImpactScore]="$initialValue()">
      @if ($isInternal() || $isSkipped()) {
        <div
          slot="info-header"
        >
          @if ($isInternal()) {
            <pw-internal-item-marker
              slot="info-header"
              [type]="$isFeature() ? 'feature' : 'bug'"
            />
          }
          @if ($isSkipped()) {
            <pw-priority-skipped-marker/>
          }
        </div>
      }
      @if (hasCrmConnected()) {
        <div slot="info-footer" class="pw-flex pw-column pw-gap-l">
          @if ($isFeature()) {
            <span>
              Total customers:
              <strong>{{ $selectedCustomers().length }}</strong>
            </span>
          }
          <span>
            Total ARR: <strong><pw-currency [amount]="$totalArr()"/></strong>
          </span>
          @if ($isFeature()) {
            <span
              matTooltip="{{
                $priority().item.customerCommitmentDate
                  | pwDateTime: 'short' : 'No commitment date provided'
              }}"
            >
              Customer commitments:
              <strong>{{
                $priority().item.committedToCustomers?.length || 0
              }}</strong>
            </span>
          }
        </div>
      }
    </pw-item-impact-form>

    <ng-container slot="summary">
      <pw-priority-summary
        [users]="$users()"
        [priority]="$priority()"
        [priorityLead]="assignees$().priorityLead"
      />
      @if (!hasCrmConnected()) {
        <pw-item-impact-score-connect-crm-message />
      }
    </ng-container>
    <pw-priority-score-submit-container slot="footer">
      @if ($priority() | pwPriorityAutoRated) {
        <span class="danger">* Rated automatically</span>
      }
      <div slot="save-button" class="pw-flex pw-gap-l">
        @if ($canSkip()) {
          <button
            mat-stroked-button
            type="button"
            color="accent"
            [disabled]="isSaving()"
            [matTooltip]="
              'Skip this step if this ' +
              ($isFeature() ? 'feature-temp' : 'bug') +
              ' has no impact on customers'
            "
            (click)="skip()"
          >
            Skip
          </button>
        }
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="isSaving()"
        >
          Submit
        </button>
      </div>
    </pw-priority-score-submit-container>
  </pw-priority-score-dialog-container>
</form>
