import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ItemPageEventHandlerProvider } from './app/adapters/item/item-page-event-handler';
import { PriorityUpdateAdapter } from './app/adapters/on-priority-update-adapter';
import { setEnv } from '@pwiz/infra/environment';
import { environment } from './environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import { PwDialogService } from '@pwiz/infra/dialog';
import { PW_MATERIAL_DEFAULTS } from '@pwiz/infra/material-defaults/ts';
import {
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
} from '@angular/router';
import { routes } from './app/routes';
import { provideToastr } from 'ngx-toastr';
import { provideNativeDateAdapter } from '@angular/material/core';

// platformBrowserDynamic()
//   .bootstrapModule(AppModule, {
//     ngZoneEventCoalescing: false,
//   })
//   .catch((err) => console.error(err));

bootstrapApplication(AppComponent, {
  providers: [
    provideNativeDateAdapter(),
    provideAuth0({
      ...environment.auth0,
      authorizationParams: {
        ...environment.auth0.authorizationParams,
        audience: `https://${environment.auth0.domain}/api/v2/`,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.backendUrl}/api/*`,
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: `https://${environment.auth0.domain}/api/v2/`,
              },
            },
          },
        ],
      },
    }),
    provideAnimationsAsync(),
    ItemPageEventHandlerProvider,
    PriorityUpdateAdapter,
    setEnv({
      backendUrl: environment.backendUrl,
      clientUrl: environment.auth0.authorizationParams.redirect_uri,
      isProd: environment.production,
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    PwDialogService,
    ...PW_MATERIAL_DEFAULTS,
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding(), ...debugRouter()),
    provideToastr(),
  ],
});

function debugRouter() {
  return sessionStorage.getItem('debug_router') ? [withDebugTracing()] : [];
}
