import { Routes } from '@angular/router';
import { ON_BOARDING_ROUTE, onBoardingGuard } from '@pwiz/on-boarding/feature';
import {
  AuthComponent,
  LoginRedirectPageComponent,
  pwAuthRedirect,
} from '@pwiz/auth/feature';
import { AdminLayoutComponent } from '@pwiz/layout/feature';
import { PriorityUpdateAdapter } from './adapters/on-priority-update-adapter';
import { inject } from '@angular/core';
import { OrganizationService } from '@pwiz/organization/ui-data';
import { CurrentUserService } from '@pwiz/user/ui-data';
import { filter } from 'rxjs';
import { ITEMS_ROUTE } from '@pwiz/item/feature';
import { PRIORITIES_ROUTES } from '@pwiz/priorities/pages/feature';
import { getSettingsRoute } from '@pwiz/global-settings/feature';
import { SLACK_ROUTE } from '@pwiz/chat/feature';
import { JIRA_SETTINGS_ROUTES } from '@pwiz/jira/feature';
import { SPRINT_LAYOUT_ROUTE } from '@pwiz/sprint/feature';
import { CUSTOMER_LIFE_CYCLE_ROUTE } from '@pwiz/customer-lifecycle/feature';
import { USER_ROUTES } from '@pwiz/user/feature';
import { STRATEGY_ROUTES } from '@pwiz/strategy/feature';
import { SIDEBAR_ROUTES } from './sidebar-routing/sidebar-routes';
import { SALESFORCE_ROUTE } from '@pwiz/crm/salesforce/feature';
import { DASHBOARD_ROUTES } from '@pwiz/dashboard/feature';
import { ROADMAP_ROUTES } from '@pwiz/roadmap/feature';

export const routes: Routes = [
  ON_BOARDING_ROUTE,
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'on-login',
    component: LoginRedirectPageComponent,
  },
  {
    path: '',
    canActivate: [pwAuthRedirect, onBoardingGuard],
    component: AdminLayoutComponent,
    providers: [PriorityUpdateAdapter],
    resolve: [
      () =>
        inject(OrganizationService).organizationRaw$.pipe(
          filter(({ status }) => status === 'success'),
        ),
      () =>
        inject(CurrentUserService)
          .userRaw$()
          .pipe(filter((status) => status === 'success')),
    ],
    children: [
      ITEMS_ROUTE(),
      PRIORITIES_ROUTES,
      getSettingsRoute(SLACK_ROUTE, SALESFORCE_ROUTE, ...JIRA_SETTINGS_ROUTES),
      SPRINT_LAYOUT_ROUTE,
      CUSTOMER_LIFE_CYCLE_ROUTE,
      ...USER_ROUTES,
      ...STRATEGY_ROUTES,
      ...ROADMAP_ROUTES,
      ...DASHBOARD_ROUTES,
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      ...SIDEBAR_ROUTES,
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
