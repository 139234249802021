<div class="label-container">
  <mat-chip-listbox
    [formControl]="form.controls.effort"
    class="mat-mdc-chip-set-stacked"
  >
    @for (effort of effortScores; track effort.id) {
      <mat-chip-option color="accent" selected [value]="effort.id">
        {{ effort.value | pwEnumToReadableString}}
      </mat-chip-option>
    }
  </mat-chip-listbox>
  <div>
    @for (effort of effortScores; track effort.id) {
      <p class="row-label pw-flex pw-center-vr">
        Up to {{ effortTimeMap$()?.[effort.id] }}
        <ng-container [ngPlural]="effortTimeMap$()?.[effort.id] || 0">
          <ng-template ngPluralCase="=1">Day</ng-template>
          <ng-template ngPluralCase="other">Days</ng-template>
        </ng-container>
      </p>
    }
  </div>
</div>
<div class="info-container pw-flex pw-column pw-gap-l">
  <ng-content select="[slot=info-header]" />
  <mat-form-field subscriptSizing="dynamic" class="pw-flex-1">
    <mat-label> Add Reason </mat-label>
    <textarea matInput [formControl]="form.controls.reason"></textarea>
  </mat-form-field>
  <ng-content />
</div>
