import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ePriorityStatus,
  ePriorityType,
  findPriorityByType,
} from '@pwiz/entity/ts';
import { isItemBug, isItemInternal, UiItemWithPriorities } from '@pwiz/item/ts';
import {
  CurrencyComponent,
  PwLabelComponent,
  PwLabelGroupComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import {
  getImpactAccountsWithoutNone,
  groupImpactCustomers,
} from '@pwiz/priority/ts';
import { CustomerListComponent, SumArrPipe } from '@pwiz/account/ui';
import { getArrSum } from '@pwiz/account/ts';

@Component({
  selector: 'pw-impact-view',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelGroupComponent,
    PwLabelComponent,
    PwLabelHeaderComponent,
    CustomerListComponent,
    CurrencyComponent,
    SumArrPipe,
    PwNullableValuePipe,
  ],
  templateUrl: './impact-view.component.html',
  styleUrl: './impact-view.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-card pw-border pw-impact-view',
  },
})
export class ImpactViewComponent {
  item = input.required<UiItemWithPriorities>();
  $impact = computed(() => {
    const item = this.item();
    return findPriorityByType(item.priorities, ePriorityType.impact);
  });
  $isAutoRated = computed(
    () => this.$impact()?.status === ePriorityStatus.autoRated,
  );
  $isInternal = computed(() => isItemInternal(this.item()));
  $isBug = computed(() => isItemBug(this.item()));
  $hasCustomers = computed(() => this.$impact()?.accountPriority.length);
  $impactGroups = computed(() =>
    this.$impact()
      ? groupImpactCustomers(this.$impact()!, this.item().type)
      : null,
  );
  $arr = computed(() => {
    const impact = this.$impact();
    if (impact) {
      return getArrSum(getImpactAccountsWithoutNone(impact));
    }
    return 0;
  });
}
