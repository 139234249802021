<pw-priority-overview-base [priority]="priority()" [users]="users()" [isReadOnly]="isReadOnly()" (updatePriority)="updatePriority.emit($event)">
  <pw-label [inLine]="true">
    <ng-container slot="header">Effort size (max):</ng-container
    >{{ priority().level | pwEnumToReadableString}}
  </pw-label>
  <pw-label [inLine]="true">
    <ng-container slot="header">Estimated development const:</ng-container>
    <pw-currency [amount]="itemEstimatedCost()"/>
  </pw-label>
</pw-priority-overview-base>
