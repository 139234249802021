import { inject, Injectable } from '@angular/core';
import {
  PrioritiesService,
  PriorityHelperService,
} from '@pwiz/priority/ui-data';
import { PwFormData } from '@pwiz/infra/form';
import {
  IConfidenceFv,
  mapConfidenceToPriority,
  mapUrgencyScoreFormToPriority,
  UrgencyScoreFormValue,
} from '@pwiz/priority/urgency/ts';
import { UiPriority } from '@pwiz/priority/ts';

@Injectable()
export class UrgencyScoreService {
  #priorityHelperService = inject(PriorityHelperService);
  #priorityService = inject(PrioritiesService);

  updateScore(priority: UiPriority, value: PwFormData<UrgencyScoreFormValue>) {
    return this.#priorityService.updateScore({
      ...priority,
      ...mapUrgencyScoreFormToPriority(value),
    });
  }

  $getPriority(priority: UiPriority) {
    return this.#priorityHelperService.$getPriority(priority);
  }

  updateConfidence(uiPriority: UiPriority, confidence: IConfidenceFv) {
    return this.#priorityService.updateConfidence(
      uiPriority.id,
      mapConfidenceToPriority(confidence),
    );
  }
}
