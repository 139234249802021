import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentUserService } from '@pwiz/user/ui-data';
import { SidebarState } from '@pwiz/layout/ts';
import { toSignal } from '@angular/core/rxjs-interop';
import { PrioritiesService } from '@pwiz/priority/ui-data';
import { LayoutFeatureComponent } from '../layout-feature/layout-feature.component';
import {
  HeaderComponent,
  SidebarComponent,
  SidebarLinkComponent,
} from '@pwiz/layout/ui';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { LayoutService } from '@pwiz/layout/ui-data';
import { ItemsSearchComponent } from '../items-search/items-search.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import {
  MenuTriggerArrowComponent,
  MenuTriggerComponent,
  RotateDirective,
  RotateMenuArrowDirective,
  ShortNumberPipe,
} from '@pwiz/infra/ui';
import {
  ContentProjectionComponent,
  provideContentProjection,
} from '@pwiz/infra/content-projection/feature';
import { useEnv } from '@pwiz/infra/environment';

@Component({
  selector: 'pw-admin-layout',
  standalone: true,
  imports: [
    CommonModule,
    LayoutFeatureComponent,
    HeaderComponent,
    SidebarComponent,
    RouterOutlet,
    PortalModule,
    ItemsSearchComponent,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    MatIconModule,
    SidebarLinkComponent,
    MenuTriggerComponent,
    MenuTriggerArrowComponent,
    RotateMenuArrowDirective,
    RotateMenuArrowDirective,
    RotateDirective,
    ContentProjectionComponent,
    ShortNumberPipe,
  ],
  providers: [LayoutService, provideContentProjection()],
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminLayoutComponent {
  #userService = inject(CurrentUserService);
  #priorityService = inject(PrioritiesService);
  $isProd = signal(useEnv().isProd);
  route = inject(ActivatedRoute);

  $sidebarState = signal<SidebarState>('closed');
  $newPriorities = this.#priorityService.countNewPriorities$();
  $user = toSignal(this.#userService.getAuthenticatedUser$(), {
    initialValue: null,
  });

  onLogout() {
    this.#userService.logout();
  }

  onMenuIconClicked() {
    this.$sidebarState.update((current) =>
      current === 'open' ? 'closed' : 'open',
    );
  }

  onSidebarClosed() {
    this.$sidebarState.set('closed');
  }
}
