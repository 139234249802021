import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {CONNECT_CRM_MESSAGE} from "../consts/connect-crm-message";

@Component({
  selector: 'pw-item-impact-score-connect-crm-message',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './item-impact-score-connect-crm-message.component.html',
  styleUrls: ['./item-impact-score-connect-crm-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-card'
  }
})
export class ItemImpactScoreConnectCrmMessageComponent {
  msg = CONNECT_CRM_MESSAGE;
}
