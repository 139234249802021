import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy,} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Observable, ReplaySubject, switchMap} from "rxjs";

@Component({
  selector: 'pw-on-after-view-init',
  standalone: true,
  imports: [CommonModule],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PwAfterViewInit$ implements AfterViewInit, OnDestroy {

  private _afterViewInit = new ReplaySubject<void>(1);
  ngAfterViewInit(): void {
    this._afterViewInit.next();
  }

  ngOnDestroy() {
    this._afterViewInit.complete();
  }
  afterViewInit$<T>(obs: () => Observable<T>) {
    return this._afterViewInit.asObservable().pipe(switchMap(obs));
  }
}
