import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IJiraInnerContent } from '@pwiz/entity/ts';
import { JiraDescriptionTextComponent } from '../description/jira-description-text.component';

@Component({
  selector: 'pw-jira-description-list',
  standalone: true,
  imports: [CommonModule, JiraDescriptionTextComponent],
  templateUrl: './jira-description-list.component.html',
  styleUrl: './jira-description-list.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JiraDescriptionListComponent {
  @Input({ required: true })
  li!: IJiraInnerContent;
}
