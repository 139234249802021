<pw-layout-feature
  [sidebarState]="$sidebarState()"
  (sidebarClosed)="onSidebarClosed()"
>
  <pw-header
    [user]="$user"
    (menuIconClicked)="onMenuIconClicked()"
    (logout)="onLogout()"
    slot="header"
  >
    <pw-content-projection key="layout_page_title" slot="start" />
    <div slot="end" class="pw-flex pw-center-vr pw-gap">
      <pw-items-search />
      <button
        class="profile-container pw-flex pw-center-vr pw-gap-s"
        mat-button
        [matMenuTriggerFor]="menu"
      >
        <img alt="Profile Photo" [src]="$user()?.picture" />
        <pw-menu-trigger-arrow
          matButtonIcon
          iconPositionEnd
          pwRotateMenuArrow
        />
      </button>
      <mat-menu #menu="matMenu">
        <a routerLink="settings/org" mat-menu-item
          ><mat-icon>settings</mat-icon> Settings</a
        >
        <a routerLink="user-preferences" mat-menu-item>
          <mat-icon>person</mat-icon>
          User preferences</a
        >
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>logout</mat-icon>
          Logout
        </button>
      </mat-menu>
    </div>
  </pw-header>

  <pw-sidebar slot="navbar" [isProd]="$isProd()">
    @if ($newPriorities()) {
      <span class="pw-badge" slot="new-priorities-badge">{{$newPriorities() | pwShortNumber }}</span>
    }
  </pw-sidebar>

  <router-outlet />
  <router-outlet name="sidebar" />
</pw-layout-feature>
