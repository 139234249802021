@if ($priorities(); as priorities) {
  @if (item(); as item) {
    <mat-accordion multi>
      @for (priority of priorities; track priority.id) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ domainMap[priority.type] }}
              {{ priority.displayType | pwEnumToReadableString }}
            </mat-panel-title>
            <mat-panel-description
              class="priority-score"
              [ngClass]="{ danger: priority.status === newPriority }"
            >
              Score: {{ priority | priorityScore }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <pw-priority-overview
            [isReadOnly]="$isDone()"
            [priority]="priority"
            [item]="item"
            (priorityClick)="onPriorityClick($event)"
            (viewTickets)="onViewTickets()"
            [users]="users()"
          />
        </mat-expansion-panel>
      }
    </mat-accordion>
  }
}
