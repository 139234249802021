import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
} from '@angular/core';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import {
  BasicAutocompleteComponent,
  IdValuePairPipe,
  PwFindByIdPipe,
  toFormValueSignal,
} from '@pwiz/infra/ui';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  ConfidenceForm,
  ConfidenceKeyResult,
  ConfidenceKeyResultForm,
} from '@pwiz/priority/urgency/ts';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { BaseEntityUtils } from '@pwiz/entity/ts';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { PwValidators } from '@pwiz/infra/form';
import { KeyResultWithBusinessGoal } from '@pwiz/strategy/ts';

@Component({
  selector: 'pw-key-result-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'key-result-form.component.html',
  styleUrl: 'key-result-form.component.scss',
  providers: [provideParentControl()],
  imports: [
    BasicAutocompleteComponent,
    IdValuePairPipe,
    MatAutocompleteOrigin,
    MatFormField,
    MatInput,
    MatLabel,
    PwFindByIdPipe,
    ReactiveFormsModule,
    MatTable,
    MatCell,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatIconButton,
    MatIcon,
    MatSuffix,
  ],
})
export class KeyResultFormComponent implements OnInit {
  #parentControlContainer =
    useParentControlContainer<FormGroup<ConfidenceForm>>();
  #fb = inject(FormBuilder);
  form = this.#initForm();
  keyResultList = input.required<KeyResultWithBusinessGoal[]>();
  $formValue = toFormValueSignal(this.form);
  $selectedKeyResultIdList = computed(() =>
    this.$formValue().map(({ keyResultId }) => keyResultId),
  );
  dataSource$ = toObservable(this.$selectedKeyResultIdList);
  $selectedKeyResultList = computed(() => {
    return this.$selectedKeyResultIdList().map((keyResultId) =>
      keyResultId
        ? BaseEntityUtils.findEntityById(this.keyResultList(), keyResultId)
        : null,
    );
  });
  $unselectedKeyResultList = computed(() =>
    this.keyResultList()
      .filter(({ id }) => !this.$selectedKeyResultIdList().includes(id))
      .map(({ id, name, businessGoal }) => ({
        id: id,
        value: `${name} (Objective: ${businessGoal.name} - P${businessGoal.priority})`,
        searchBy: `${name} ${businessGoal.name} P${businessGoal.priority}`,
      })),
  );
  displayColumns = [
    'priority',
    'businessGoal',
    'main',
    'keyResult',
    'target',
    'delete',
  ];
  value = input.required<ConfidenceKeyResult[]>();

  constructor() {
    effect(
      () => {
        const confidenceKeyResults = this.value();
        for (let i = 0; i < this.form.controls.length; i++) {
          this.remove(i);
        }
        confidenceKeyResults.forEach((kr) => {
          this.form.push(this.#createRow(kr.keyResultId, kr.target));
        });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {
    this.#parentControlContainer.control.setControl('keyResultList', this.form);
  }

  #initForm() {
    return this.#fb.array<FormGroup<ConfidenceKeyResultForm>>([]);
  }

  #createRow(keyResultId: string, target: number | null = null) {
    return createConfidenceKeyResultFormRow(keyResultId, target);
  }

  keyResultSelected(
    keyResultId: string | null,
    keyResultAutocomplete: BasicAutocompleteComponent,
  ) {
    if (!keyResultId) {
      return;
    }
    this.form.push(this.#createRow(keyResultId));
    keyResultAutocomplete.reset();
  }

  remove(index: number) {
    this.form.removeAt(index);
  }
}

export function createConfidenceKeyResultFormRow(
  keyResultId: string,
  target: number | null,
) {
  return new FormGroup<ConfidenceKeyResultForm>({
    keyResultId: new FormControl(keyResultId),
    target: new FormControl(target, [PwValidators.int]),
  });
}
