import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  DestroyRef,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import {
  filterTableTickets,
  getTicketItem,
  ITicketTableFilterFC,
  ITicketTableFilterFV,
  toTableTicket,
} from '@pwiz/ticket/ts';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ExternalLinkComponent,
  PwCurrencyPipe,
  PwEnumToReadableStringPipe,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { $useSettingsSafe } from '@pwiz/settings/ui-data';
import { IItem } from '@pwiz/entity/ts';
import {
  TicketIdComponent,
  TicketTableActionDirective,
  TicketTableComponent,
  TicketTableFilterComponent,
  TicketTableIconDirective,
} from '@pwiz/ticket/ui';
import { filterNotNull } from '@pwiz/infra/ts';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideParentControl } from '@pwiz/infra/form-2/ui';
import { TicketTableDataAdapter } from '@pwiz/ticket/ui-data';
import { map } from 'rxjs';

@Component({
  selector: 'pw-ticket-table-smart',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    PwNullableValuePipe,
    PwEnumToReadableStringPipe,
    PwCurrencyPipe,
    ExternalLinkComponent,
    TicketIdComponent,
    TicketTableComponent,
    TicketTableFilterComponent,
    ReactiveFormsModule,
    TicketTableActionDirective,
    TicketTableIconDirective,
  ],
  templateUrl: './ticket-table-smart.component.html',
  styleUrl: './ticket-table-smart.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideParentControl()],
})
export class TicketTableSmartComponent implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  data = inject(TicketTableDataAdapter);
  form = new FormGroup<ITicketTableFilterFC>({});

  $users = this.data.$availableUsers;
  $settings = $useSettingsSafe();
  $tickets = toSignal(this.data.tickets$);
  $tableTickets = computed(() => {
    const tickets = this.$tickets();
    const settings = this.$settings();
    if (!tickets) {
      return [];
    }
    return tickets?.map((ticket) => {
      return toTableTicket(
        ticket,
        (this.data.item || getTicketItem(ticket)) as IItem,
        settings,
      );
    });
  });

  $filteredTickets = computed(() => {
    const tickets = this.$tableTickets();
    const filter = this.data.filters?.$filters();
    return filter ? filterTableTickets(tickets, filter) : tickets;
  });

  $displayColumns = this.data.displayColumns;
  $action = contentChild(TicketTableActionDirective);
  $ticketIconTemplate = contentChild(TicketTableIconDirective);

  ngAfterViewInit() {
    if (this.data.filters) {
      this.form.setValue(
        { filter: this.data.filters.$filters() },
        { emitEvent: false },
      );
    }
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map(({ filter }) => filter),
      )
      .subscribe((filter) =>
        this.onFilterChange(filter as ITicketTableFilterFV),
      );
  }

  onFilterChange({ status, priority, assignee, type }: ITicketTableFilterFV) {
    this.data.filters?.setFilters({
      status: status?.filter(filterNotNull) || [],
      priority: priority?.filter(filterNotNull) || [],
      assignee: assignee?.filter(filterNotNull) || [],
      type: type || null,
    });
  }
}
