@if ($ticket(); as ticket) {
  <pw-label class="row">
    <pw-label-header>Summary</pw-label-header>
    {{ ticket.summery }}
  </pw-label>

  <pw-label-group
    class="row"
    inLine
    childrenStyle="column"
    [showSeparators]="true"
  >
    <pw-label>
      <pw-label-header>Id</pw-label-header>
      <pw-external-link [href]="ticket.url">
        {{ ticket.displayId }}
      </pw-external-link>
    </pw-label>
    <pw-label>
      <pw-label-header>Status</pw-label-header>
      {{ ticket.status | pwEnumToReadableString }}
    </pw-label>
    <pw-label>
      <pw-label-header>Type</pw-label-header>
      {{ ticket.type | pwEnumToReadableString }}
    </pw-label>
    <pw-label>
      <pw-label-header>Board</pw-label-header>
      {{ ticket.board?.name }}
    </pw-label>
    <pw-label>
      <pw-label-header>Assignee</pw-label-header>
      {{ ticket.providerAssignee?.name }}
    </pw-label>
  </pw-label-group>

  <pw-label class="row">
    <pw-label-header>Description</pw-label-header>
    <pw-item-description
      [descriptionText]="ticket.descriptionText"
      [descriptionObject]="ticket.descriptionObject"
    />
  </pw-label>

  <pw-label-group childrenStyle="row" inLine>
    <pw-label>
      <pw-label-header>Effort from jira:</pw-label-header>
      {{ ticket.effortEstimation | pwNullableValue }}
    </pw-label>
    <pw-label>
      <pw-label-header>Effort cost:</pw-label-header>
      <pw-currency [amount]="$effortEstimationCost()" />
    </pw-label>

    @if ($isDone() && $actual(); as actual) {
      <pw-label>
        <pw-label-header>Actual effort (gross):</pw-label-header>
        {{ actual.effort | pwNullableValue }}
      </pw-label>
      <pw-label>
        <pw-label-header>Actual cost (gross):</pw-label-header>
        <pw-currency [amount]="actual.cost" />
      </pw-label>
    }
  </pw-label-group>
  @if ($parent(); as parent) {
    <pw-label inLine class="pw-padding pw-vertical">
      <pw-label-header
        >Parent
        {{ parent.ticketType | pwEnumToReadableString }}:</pw-label-header
      >
      <a [pwDrawerLink]="['ticket', parent.id]">
        {{ parent.summery }}
      </a>
    </pw-label>
  }
  <pw-label [inLine]="true" class="pw-padding pw-vertical">
    <pw-label-header>Relates to item:</pw-label-header>
    @if ($item(); as item) {
      <a
        [pwDrawerLink]="[
          'item',
          ticket.item?.id || ticket.epic?.item?.id || ''
        ]"
      >
        {{ item.description }}
      </a>
    } @else {
      -
    }
  </pw-label>
}
