import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriorityOverviewComponentInterface } from '../priority-overview-component-interface';
import { UiPriority } from '@pwiz/priority/ts';
import {
  CurrencyComponent,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
} from '@pwiz/infra/ui';
import { IItemSummary } from '@pwiz/item/summary/ts';
import { PwItemTimeToDeliverPipe } from '@pwiz/item/ui';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerLinkDirective } from '@pwiz/infra/drawer';
import {
  PriorityConfidenceOverviewComponent,
  PriorityEffortOverviewComponent,
} from '@pwiz/priority/ui';
import { PwUser } from '@pwiz/user/ts';

@Component({
  selector: 'pw-effort-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelComponent,
    PwItemTimeToDeliverPipe,
    CurrencyComponent,
    PwEnumToReadableStringPipe,
    MatIconModule,
    MatTooltipModule,
    DrawerLinkDirective,
    PriorityEffortOverviewComponent,
    PriorityConfidenceOverviewComponent,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <pw-priority-effort-overview
      [priority]="priority()"
      [itemEstimatedCost]="item().assigneeEstimatedCost"
      [users]="users()"
      [isReadOnly]="isReadOnly()"
      (updatePriority)="updatePriority()()"
    />
  `,
})
export class EffortOverviewComponent
  implements PriorityOverviewComponentInterface
{
  item = input.required<IItemSummary>();
  priority = input.required<UiPriority>();
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  updatePriority = input.required<() => void>();
}
