<div class="pw-flex-1">
  <ng-content select="[slot=info-content]" />
  <pw-priority-score-is-internal-item
    class="pw-flex-1"
    [formControl]="form.controls.isInternal"
  />
</div>
<div class="select-container">
  <mat-chip-listbox
    [formControl]="form.controls.urgency"
    class="mat-mdc-chip-set-stacked"
  >
    @for (urgency of urgencyLevels; track urgency.id) {
      <mat-chip-option color="accent" selected [value]="urgency.id">
        {{ urgency.value | pwEnumToReadableString }}
      </mat-chip-option>
    }
  </mat-chip-listbox>
</div>

<div class="info-container pw-flex pw-column">
  <mat-form-field class="reason" subscriptSizing="dynamic">
    <mat-label> Add Reason </mat-label>
    <textarea
      matInput
      [required]="$isReasonRequired()"
      [formControl]="form.controls.reason"
    ></textarea>
    <mat-error
      >Please explain why the item mis an
      {{ $formValue().urgency | pwEnumToReadableString }}</mat-error
    >
  </mat-form-field>
</div>
