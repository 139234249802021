import { ItemSummaryDialogService } from '@pwiz/item/ts';
import { Provider } from '@angular/core';
import { ItemSummaryDrawerComponent } from '@pwiz/item/summary/feature';
import { ItemSummaryDrawerData } from '@pwiz/item/summary/ts';
import { useOpenPwDrawer } from '@pwiz/infra/drawer';

class ItemSummaryDialogAdapterService implements ItemSummaryDialogService {
  #pwDrawer = useOpenPwDrawer();

  openItemSummary(itemId: string) {
    this.#pwDrawer.open<ItemSummaryDrawerData>(ItemSummaryDrawerComponent, {
      data: { itemId },
      name: 'Item summary',
    });
  }
}

export const ItemPageEventHandlerProvider: Provider = {
  provide: ItemSummaryDialogService,
  useClass: ItemSummaryDialogAdapterService,
};
