<pw-dialog-v2>
  <pw-dialog-header>
    <pw-priority-score-dialog-header [priority]="$priority()">
      Severity Scoring
    </pw-priority-score-dialog-header>
    <pw-priority-summary
      slot="summary"
      [priority]="$priority()"
      [users]="$users()"
      [priorityLead]="assignees().priorityLead"
      [design]="2"
    />
  </pw-dialog-header>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <pw-dialog-content>
      <div class="content">
        <pw-urgency-score-form class="form" />
        @if ($itemSummary(); as item) {
          <pw-impact-view [item]="item" class="impact" />
        }
      </div>
    </pw-dialog-content>
    <pw-dialog-footer>
      <div pwDialogFooter="start">
        @if ($priority() | pwPriorityAutoRated) {
          <span class="danger">* Rated automatically</span>
        }
        @if (showWarning()) {
          <p class="pw-warning">
            Be aware that setting the severity level to "immediate fix" will
            immediately ignore all other priorities and set priority to
            CRITICAL.
          </p>
        }
      </div>
      <button
        pwDialogFooter="end"
        class="submit-button"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="saving()"
      >
        Submit
      </button>
    </pw-dialog-footer>
  </form>
</pw-dialog-v2>
