import { pwCacheUpdateEntityArr, PwdCacheById } from '@pwiz/infra/cache/ts';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITicket, ITicketResponse, toTicket } from '@pwiz/entity/ts';
import { map } from 'rxjs';
import { TICKET_API_ENDPOINT } from '@pwiz/ticket/ts';

@Injectable({ providedIn: 'root' })
export class TicketService {
  #cache = new PwdCacheById<ITicket>(
    (id) => this.#getByItem(id),
    pwCacheUpdateEntityArr,
  );
  #url = useBaseApiUrl(TICKET_API_ENDPOINT);
  #httpClient = inject(HttpClient);

  #getByItem(itemId: string) {
    return this.#httpClient
      .get<ITicketResponse[]>(`${this.#url}/${itemId}/by-item`)
      .pipe(map((tickets) => tickets.map(toTicket)));
  }

  getByItem(itemId: string) {
    return this.#cache.get(itemId);
  }

  getTicket(ticketId: string) {
    console.log(ticketId);
    return this.#httpClient
      .get<ITicketResponse>(`${this.#url}/${ticketId}`)
      .pipe(map(toTicket));
  }
}
