import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { useCloseDialogFinished, useDialogData } from '@pwiz/infra/dialog';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ImpactScoreFormComponent,
  ItemImpactScoreConnectCrmMessageComponent,
} from '@pwiz/priority/impact/ui';
import {
  InternalItemMarkerComponent,
  PriorityAutoRatedPipe,
  PriorityScoreDialogContainerComponent,
  PriorityScoreDialogHeaderComponent,
  PriorityScoreFormContainerComponent,
  PriorityScoreFormContainerDirective,
  PriorityScoreItemSummeryContainerDirective,
  PriorityScoreSubmitContainerComponent,
  PrioritySummaryComponent,
} from '@pwiz/priority/ui';
import { MatButtonModule } from '@angular/material/button';
import {
  HighlightedChipComponent,
  PwOnInit$,
  PwRoundPipe,
  CurrencyComponent,
} from '@pwiz/infra/ui';
import { PriorityHelperService } from '@pwiz/priority/ui-data';
import { PriorityEffortService } from '@pwiz/priority/effort/ui-data';
import { PriorityEffortFormComponent } from '@pwiz/priority/effort/ui';
import {
  getItemMaxCost,
  PriorityEffortFormContainer,
  PriorityEffortFormValue,
  toPriorityEffortForm,
} from '@pwiz/priority/effort/ts';
import { startWith } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { useRunInInjectorContext } from '@pwiz/infra/hooks';
import { UiPriority } from '@pwiz/priority/ts';
import { isItemBug } from '@pwiz/item/ts';
import { eItemType } from '@pwiz/entity/ts';
import { $useUserList } from '@pwiz/user/ui-data';

@Component({
  selector: 'pw-effort-score-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ImpactScoreFormComponent,
    ItemImpactScoreConnectCrmMessageComponent,
    PrioritySummaryComponent,
    MatButtonModule,
    CurrencyComponent,
    PwRoundPipe,
    ReactiveFormsModule,
    PriorityScoreDialogContainerComponent,
    PriorityScoreFormContainerDirective,
    PriorityScoreItemSummeryContainerDirective,
    PriorityEffortFormComponent,
    PriorityScoreSubmitContainerComponent,
    PriorityScoreDialogHeaderComponent,
    PriorityScoreFormContainerComponent,
    HighlightedChipComponent,
    InternalItemMarkerComponent,
    PriorityAutoRatedPipe,
  ],
  templateUrl: './effort-score-dialog.component.html',
  styleUrls: ['./effort-score-dialog.component.scss'],
  providers: [PriorityHelperService, PriorityEffortService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EffortScoreDialogComponent
  extends PwOnInit$
  implements AfterViewInit
{
  #priorityEffortService = inject(PriorityEffortService);

  #averageEngineerCost = this.#priorityEffortService.getAverageEngineerCost$();
  #dialogFinished = useCloseDialogFinished();
  #inInjectionContext = useRunInInjectorContext();
  #saving = signal(false);
  #partialPriority = useDialogData<{ priority: UiPriority }>().priority;
  $priority = this.#priorityEffortService.$getPriority(this.#partialPriority);
  $isBug = computed(() => {
    const uiPriority = this.$priority();
    return uiPriority.item ? isItemBug(uiPriority.item) : false;
  });
  $users = $useUserList();
  $assignees = this.#priorityEffortService.getAssignee$();
  $disableSaveBtn = computed(
    () => this.$formStatus() !== 'VALID' || !this.#saving(),
  );

  effortTimeMap = this.#priorityEffortService.getEffortTimes();
  effortTimeMapByItem = computed(() =>
    this.$priority().item.type === eItemType.feature
      ? this.effortTimeMap()?.feature
      : this.effortTimeMap()?.bug,
  );

  itemMaxCost: Signal<number> = this.#getItemCost();
  form = new FormGroup<PriorityEffortFormContainer>(
    {} as PriorityEffortFormContainer,
  );
  formValue = toSignal(
    this.onInit$(() => this.form.valueChanges.pipe(startWith(this.form.value))),
  );
  $formStatus = toSignal(this.onInit$(() => this.form.statusChanges));
  isInternal = computed(() =>
    this.#priorityEffortService.isInternal(this.$priority()),
  );

  #formValue = computed(() => toPriorityEffortForm(this.$priority()));
  ngAfterViewInit() {
    this.#inInjectionContext(() =>
      effect(() => this.form.setValue({ effortScore: this.#formValue() }), {
        allowSignalWrites: true,
      }),
    );
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.#saving.set(true);
    this.#priorityEffortService
      .updatePriority(
        this.$priority(),
        this.form.value.effortScore as PriorityEffortFormValue,
      )
      .subscribe(() => {
        this.#dialogFinished({ ok: true, response: null });
      });
  }

  #getItemCost() {
    return computed(() => {
      const [averageEngineerCost, effortFormValue, effortTimeMap] = [
        this.#averageEngineerCost() || 0,
        this.formValue(),
        this.effortTimeMap(),
      ];
      if (!effortTimeMap || !effortFormValue?.effortScore?.effort) {
        return 0;
      }
      return getItemMaxCost(
        averageEngineerCost,
        effortTimeMap,
        effortFormValue?.effortScore.effort,
      );
    });
  }
}
