import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { IAccount } from '@pwiz/entity/ts';
import { MatTooltip } from '@angular/material/tooltip';
import { PwShortCurrencyPipe } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-customer-list',
  standalone: true,
  imports: [CommonModule, MatChipSet, MatChip, MatTooltip, PwShortCurrencyPipe],
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerListComponent {
  accountList = input.required<IAccount[]>();
}
