<ng-container [formGroup]="form">
  <!--  Dev feature / customer req -->
  <section class="pw-flex general-improvement">
    <mat-checkbox
      [formControl]="form.controls.isCustomerReq"
      matTooltip="Customers have requested this feature"
      matTooltipPosition="after"
      >Customer request</mat-checkbox
    >
    <mat-checkbox
      [formControl]="form.controls.isInternal"
      matTooltip="None customer facing development"
      matTooltipPosition="after"
      >Dev feature / improvement</mat-checkbox
    >
  </section>
  <!--  confidence level-->
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    matTooltip="How confident are you that this feature has high business
      value?"
    matTooltipPosition="above"
    class="confidence-input"
  >
    <mat-label>Confidence level</mat-label>
    <mat-select [formControl]="form.controls.confidence">
      @for (urgency of urgencyLevels; track urgency) {
        <mat-option [value]="urgency.id">
          {{ urgency.value | pwEnumToReadableString }} ({{ urgency.extra }})
        </mat-option>
      }
    </mat-select>
    <mat-error>Confidence level is required</mat-error>
  </mat-form-field>

  <div class="slot-container">
    <ng-content />
  </div>

  <!--  reason -->
  <mat-form-field class="reason" subscriptSizing="dynamic">
    <mat-label> Add Reason</mat-label>
    <textarea
      matInput
      [required]="$isReasonRequired()"
      [formControl]="form.controls.reason"
    ></textarea>
    <mat-error
      >Please explain why is your confidence level that high
    </mat-error>
  </mat-form-field>

  <pw-key-result-form class="business-goals" [keyResultList]="keyResultList()" [value]="value().keyResultList"/>

  <!--  Paid feature, goals, target competitors, innovative -->
  <mat-accordion class="accordion">
    <mat-expansion-panel>
      <mat-expansion-panel-header>Specifications</mat-expansion-panel-header>
      <section class="toggle-list-container pw-flex pw-column pw-gap">
        <p class="toggle">Paid feature:</p>
        <div class="toggle-buttons">
          <mat-button-toggle-group
            name="fontStyle"
            aria-label="Font Style"
            [formControl]="form.controls.isPaidFeature"
          >
            <mat-button-toggle [value]="false">No</mat-button-toggle>
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        @if ($formValue().isPaidFeature) {
          <div class="pw-flex pw-gap other paid-feature-fields">
            <mat-form-field>
              <mat-label>Avg pay</mat-label>
              <input
                matInput
                type="number"
                [formControl]="form.controls.avgCost"
              />
              <span matPrefix>$</span>
              <mat-error>Value must be greater than 0</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label># of customers</mat-label>
              <input
                matInput
                type="number"
                [formControl]="form.controls.numberOfPayingCustomers"
              />
              <mat-error>Value must be greater than 0</mat-error>
            </mat-form-field>
          </div>
        }
        <p class="toggle">Exists in competitors:</p>
        <div class="toggle-buttons">
          <mat-button-toggle-group
            name="fontStyle"
            aria-label="Font Style"
            [formControl]="form.controls.existsInCompetitors"
          >
            <mat-button-toggle [value]="false">No</mat-button-toggle>
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <p class="toggle">Innovative:</p>
        <div class="toggle-buttons">
          <mat-button-toggle-group
            name="fontStyle"
            aria-label="Font Style"
            [formControl]="form.controls.innovative"
          >
            <mat-button-toggle [value]="false">No</mat-button-toggle>
            <mat-button-toggle [value]="true">Yes</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <pw-separated-section class="validation">
          <pw-separated-section-header
            class="pw-flex pw-center-vr pw-gap"
            matTooltip="Deadlines affect feature sla times"
          >
            Validation
          </pw-separated-section-header>
          <div class="pw-scroll-container">
            <pw-validation
              [formControl]="form.controls.validation"
              class="pw-scroll"
            />
          </div>
        </pw-separated-section>
      </section>
    </mat-expansion-panel>
  </mat-accordion>

  <pw-separated-section class="commitment">
    <pw-separated-section-header
      class="pw-flex pw-center-vr pw-gap"
      matTooltip="Deadlines affect feature sla times"
    >
      Commitment & deadline
    </pw-separated-section-header>
    <div class="commitment-container">
      <span
        class="pw-flex pw-center-vr customer-info pw-gap"
        matTooltip="Use this if the feature was promised to customers"
      >
        Customer Commitment
        <mat-icon>info</mat-icon>
      </span>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="customers"
      >
        <mat-label>Committed to</mat-label>
        <mat-select
          multiple
          [formControl]="form.controls.committedToCustomerIds"
        >
          @for (account of accounts(); track account.id) {
            <mat-option [value]="account.id">
              {{ account.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="commitment-date" subscriptSizing="dynamic">
        <mat-label>Commitment date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [formControl]="form.controls.customerCommitmentDate"
          [min]="minDate"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker" />
        <mat-datepicker #picker />
      </mat-form-field>
      <span
        matTooltip="Use this to mark an end date for the faeture that doesn't affect cusstomers"
        class="deadline-info pw-gap pw-flex pw-center-vr"
      >
        Internal deadline
        <mat-icon>info</mat-icon>
      </span>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Finish by</mat-label>
        <input
          matInput
          [matDatepicker]="internalPicker"
          [formControl]="form.controls.internalDeadLine"
          [min]="minDate"
          [max]="$maxDate()"
        />
        <mat-datepicker-toggle matIconSuffix [for]="internalPicker" />
        <mat-datepicker #internalPicker />
        <mat-error>
          @if (form.controls.internalDeadLine.errors; as error) {
            @if (error?.['matDatepickerMax']) {
              Deadline must be BEFORE
              {{ error?.['matDatepickerMax'].max | pwDateTime: 'shortDate' }}
            } @else if (error?.['matDatepickerMin']) {
              Deadline must be AFTER
              {{ error?.['matDatepickerMin'].min | pwDateTime: 'shortDate' }}
            }
          }
        </mat-error>
      </mat-form-field>
    </div>
  </pw-separated-section>
</ng-container>
