<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let ticket">
      @if ($action(); as action) {
        <ng-container
          [ngTemplateOutlet]="action.templateRef || null"
          [ngTemplateOutletContext]="{ $implicit: ticket }"
        />
      } @else {
        <button
          class="summary-button"
          mat-icon-button
          (click)="onSummaryClick(ticket)"
        >
          <i class="tim-icons icon-zoom-split"></i>
        </button>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let ticket">
      <pw-external-link [href]="ticket.url">
        {{ ticket.displayId }}
      </pw-external-link>
    </td>
  </ng-container>
  <ng-container matColumnDef="idWithIcon">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let ticket">
      <div class="pw-flex pw-gap">
        <pw-ticket-id [ticket]="ticket" />
        @if ($ticketIconTemplate(); as icon) {
        <ng-container
          [ngTemplateOutlet]="icon.templateRef"
          [ngTemplateOutletContext]="{ $implicit: ticket }"
        />
        }
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="summary">
    <th mat-header-cell *matHeaderCellDef>Summary</th>
    <td mat-cell *matCellDef="let ticket">{{ ticket.summery }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let ticket">
      {{ ticket.uiStatus | pwEnumToReadableString }}
    </td>
  </ng-container>
  <ng-container matColumnDef="estimationHours">
    <th mat-header-cell *matHeaderCellDef>Effort (h)</th>
    <td mat-cell *matCellDef="let ticket">
      {{ ticket.effortHours | pwNullableValue }}
    </td>
  </ng-container>
  <ng-container matColumnDef="estimation">
    <th mat-header-cell *matHeaderCellDef>Effort</th>
    <td mat-cell *matCellDef="let ticket">
      {{ ticket.effortEstimation | pwNullableValue }}
    </td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>Cost</th>
    <td mat-cell *matCellDef="let ticket">
     <pw-currency [amount]="ticket.cost"/>
    </td>
  </ng-container>
  <ng-container matColumnDef="assignee">
    <th mat-header-cell *matHeaderCellDef>Assignee</th>
    <td mat-cell *matCellDef="let ticket">
      {{ ticket.providerAssignee?.name | pwNullableValue }}
    </td>
  </ng-container>
  <ng-container matColumnDef="board">
    <th mat-header-cell *matHeaderCellDef>Board</th>
    <td mat-cell *matCellDef="let ticket">{{ ticket.board.name }}</td>
  </ng-container>
  <ng-container matColumnDef="sla">
    <th mat-header-cell *matHeaderCellDef>SLA</th>
    <td
      mat-cell
      *matCellDef="let ticket"
      [ngClass]="{ danger: ticket.sla < 0 }"
    >
      {{ ticket.sla | pwItemTimeToDeliver }}
    </td>
  </ng-container>
  <ng-container matColumnDef="priority">
    <th mat-header-cell *matHeaderCellDef>Priority</th>
    <td mat-cell *matCellDef="let ticket">
      <pw-item-priority [item]="ticket.item || ticket.epic?.item" />
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="$displayColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: $displayColumns()"></tr>
</table>
