<pw-dialog-v3>
  <pw-dialog-header>
    <pw-priority-score-dialog-header [priority]="$priority()">
      Confidence
    </pw-priority-score-dialog-header>
    <pw-priority-summary
      slot="summary"
      [users]="$users()"
      [priority]="$priority()"
      [priorityLead]="$assignees().priorityLead"
      [design]="2"
    />
  </pw-dialog-header>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="pw-scroll-container pw-gap-inherit">
    <pw-dialog-content overflow>
      <pw-confidence-score-form
        [accounts]="$accounts()"
        [keyResultList]="$keyResults()"
        [value]="$confidenceForm()"
        class="pw-scroll-container"
      >
        @if ($item(); as item) {
          <pw-impact-view [item]="item"/>
        }
      </pw-confidence-score-form>
    </pw-dialog-content>
    <pw-dialog-footer>
      <button
        pwDialogFooter="end"
        class="submit-button"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="saving()"
      >
        Submit
      </button>
    </pw-dialog-footer>
  </form>
</pw-dialog-v3>
