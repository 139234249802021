import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-priority-score-is-internal-item',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  templateUrl: './priority-score-is-internal-item.component.html',
  styleUrls: ['./priority-score-is-internal-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PriorityScoreIsInternalItemComponent,
      multi: true,
    },
  ],
})
export class PriorityScoreIsInternalItemComponent
  implements ControlValueAccessor
{
  #onChange?: (newValue: boolean) => void;
  #onTouched?: () => void;

  control = new FormControl<boolean>(false);
  @Input() set value(isChecked: boolean) {
    this.control.setValue(isChecked);
  }

  @Input() set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  constructor() {
    this.control.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.#onChange?.(value ?? false);
      this.#onTouched?.();
    });
  }

  registerOnChange(fn: (newValue: boolean) => void): void {
    this.#onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
  writeValue(value: boolean): void {
    this.control.setValue(value);
  }
}
