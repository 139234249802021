import { IConfidenceFv, ConfidencePutReq } from '../type';
import { TimeUtils } from '@pwiz/infra/ts';

export function mapConfidenceToPriority({
  confidence,
  reason,
  committedToCustomerIds,
  customerCommitmentDate,
  internalDeadLine,
  isInternal,
  isCustomerReq,
  isPaidFeature,
  avgCost,
  numberOfPayingCustomers,
  keyResultList,
  validation,
  ...confidenceAddOn
}: IConfidenceFv): ConfidencePutReq {
  return {
    score: confidence || 0,
    reason: reason || null,
    isInternal: isInternal || false,
    avgCost: avgCost || 0,
    isCustomerReq: isCustomerReq || false,
    isPaidFeature: isPaidFeature || false,
    numberOfPayingCustomers: numberOfPayingCustomers || null,
    customerCommitmentDate: toTimestamp(customerCommitmentDate),
    internalDeadLine: toTimestamp(internalDeadLine),
    committedToCustomerIds: committedToCustomerIds
      ? committedToCustomerIds
      : null,
    extra: {
      existsInCompetitors: confidenceAddOn.existsInCompetitors || false,
      innovative: confidenceAddOn.innovative || false,
    },
    keyResultList: keyResultList,
    validation: validation || [],
    targetPercent: null,
  };
}

function toTimestamp(date?: Date | null) {
  return !date ? null : TimeUtils.toUtcTimeStamp(date);
}
