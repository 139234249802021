import { IPriority, IPriorityReason } from '@pwiz/entity/ts';
import { UrgencyScoreFormValue } from '../type';
import {
  mapUrgencyLevelToScore,
  PriorityInternalImpactFlag,
} from '@pwiz/priority/ts';
import { PwFormData } from '@pwiz/infra/form';

export function mapUrgencyScoreFormToPriority(
  urgencyScoreForm: PwFormData<UrgencyScoreFormValue>,
): Pick<IPriority, 'score'> & PriorityInternalImpactFlag & IPriorityReason {
  return {
    score: mapUrgencyLevelToScore(urgencyScoreForm.urgency),
    isInternal: urgencyScoreForm.isInternal || false,
    reason: urgencyScoreForm.reason || null,
  };
}
