import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DrawerHeaderUiComponent,
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerFooterComponent,
  PwDrawerHeaderComponent,
  useOpenPwDrawer,
  usePwDrawerData,
} from '@pwiz/infra/drawer';
import { ItemSummaryDrawerData } from '@pwiz/item/summary/ts';
import { ItemGlanceComponent, ItemStatsComponent } from '@pwiz/item/ui';
import { ItemSummaryService } from '@pwiz/item/summary/ui-data';
import { toSignal } from '@angular/core/rxjs-interop';
import { PriorityDialogsProvider } from '@pwiz/priority/ui-data';
import { ePriorityType } from '@pwiz/entity/ts';
import { ImpactScoreDialogComponent } from '@pwiz/priority/impact/feature';
import {
  ConfidenceScoreDialogComponent,
  UrgencyScoreDialogComponent,
} from '@pwiz/priority/urgency/feature';
import { EffortScoreDialogComponent } from '@pwiz/priority/effort/feature';
import {
  ItemOverviewComponent,
  ItemPriorityOverviewComponent,
} from '@pwiz/item/summary/ui';
import { UiPriority } from '@pwiz/priority/ts';
import {
  ItemNestedTicketService,
  TicketDrawerComponent,
} from '@pwiz/ticket/feature';
import { $useSettingsSafe } from '@pwiz/settings/ui-data';
import { $useUserList } from '@pwiz/user/ui-data';
import { useItemPriorityDialog } from '@pwiz/item/feature';
import { SprintItemScheduleComponent } from '@pwiz/sprint/ui';
import { getItemIssue } from '@pwiz/item/ts';
import { PRIORITY_DIALOG_SERVICE_PROVIDER } from '@pwiz/priority/feature';

@Component({
  selector: 'pw-item-summary-drawer',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    DrawerHeaderUiComponent,
    PwDrawerContentComponent,
    PwDrawerFooterComponent,
    ItemGlanceComponent,
    ItemStatsComponent,
    ItemPriorityOverviewComponent,
    ItemOverviewComponent,
    PwDrawerHeaderComponent,
    SprintItemScheduleComponent,
  ],
  templateUrl: './item-summary-drawer.component.html',
  styleUrl: './item-summary-drawer.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ItemSummaryService, PRIORITY_DIALOG_SERVICE_PROVIDER],
})
export class ItemSummaryDrawerComponent {
  #itemSummaryService = inject(ItemSummaryService);
  #itemTicketTableAdapterService = inject(ItemNestedTicketService);
  #pwDrawer = useOpenPwDrawer();
  #priorityChangeDialog = useItemPriorityDialog();

  data = usePwDrawerData<ItemSummaryDrawerData>();
  $users = $useUserList();
  $settings = $useSettingsSafe();

  $item = toSignal(this.#itemSummaryService.getItemSummary$(this.data.itemId), {
    initialValue: null,
  });
  $itemSchedule = toSignal(
    this.#itemSummaryService.getItemSchedule(this.data.itemId),
    {
      requireSync: true,
    },
  );
  $team = computed(() =>
    this.$itemSchedule().data
      ? getItemIssue(this.$itemSchedule().data!)?.team || null
      : null,
  );

  $itemManualPriorityChangEvent = toSignal(
    this.#itemSummaryService.getItemPriorityChangeAuditEvent$(this.itemId),
    { initialValue: null },
  );

  constructor() {
    effect(() => {
      const item = this.$item();
      if (item) {
        this.#itemTicketTableAdapterService.item = item;
      }
    });
  }

  get itemId() {
    return this.data.itemId;
  }

  onViewPriority({ priority }: { priority: UiPriority }) {
    const item = this.$item();
    if (!item) {
      return;
    }
    this.#itemSummaryService.openPriorityDialog(priority);
  }

  onViewTickets() {
    this.#pwDrawer.open(TicketDrawerComponent, {
      name: `Tickets`,
      data: { itemId: this.data.itemId },
    });
  }

  onPriorityClick() {
    this.#priorityChangeDialog(this.data.itemId).subscribe();
  }
}
