import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@pwiz/layout/ui';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SCREEN_BREAK_POINT_MAP, SidebarState } from '@pwiz/layout/ts';
import { PwOnInitSignal } from '@pwiz/infra/ui';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { PwDrawerComponent, PwDrawerService } from '@pwiz/infra/drawer';

@Component({
  selector: 'pw-layout-feature',
  standalone: true,
  imports: [CommonModule, HeaderComponent, MatSidenavModule, PwDrawerComponent],
  templateUrl: './layout-feature.component.html',
  styleUrls: ['./layout-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'new-styles',
  },
})
export class LayoutFeatureComponent extends PwOnInitSignal {
  #breakPointObserver = inject(BreakpointObserver);
  #element: ElementRef<HTMLElement> = inject(ElementRef);
  #pwDrawer = inject(PwDrawerService);
  $isDrawerOpen = this.#pwDrawer.$isOpen;
  handleDrawerClose = () => this.#pwDrawer.onClose();

  screenSize = toSignal(
    this.#breakPointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((result) => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              return SCREEN_BREAK_POINT_MAP.get(query) ?? null;
            }
          }
          return null;
        }),
      ),
  );

  sidebarState = input.required<SidebarState>();

  sidebarClosed = output<void>();

  isMedium = computed(() => {
    const screen = this.screenSize();
    return !!screen && ['s'].includes(screen);
  });

  sidebarOpen = computed(() => {
    if (!this.$didInit()) {
      return false;
    }
    const isOpen = this.sidebarState() === 'open';

    if (this.isMedium()) {
      return isOpen;
    }
    isOpen
      ? this.#element.nativeElement.classList.add('expanded')
      : this.#element.nativeElement.classList.remove('expanded');
    return true;
  });

  hasBackdrop = computed(() => {
    return this.isMedium();
  });

  onSidebarClosed() {
    this.sidebarClosed.emit();
  }
}
