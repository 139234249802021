<mat-sidenav-container hasBackdrop class="full-screen-container">

  <mat-sidenav [opened]="$isDrawerOpen()" mode="over" position="end" (closed)="handleDrawerClose()">
    <pw-drawer/>
  </mat-sidenav>

  <header>
    <ng-content select="[slot=header]" />
  </header>
  <mat-sidenav-container [hasBackdrop]="hasBackdrop()" class="main-content">
    <mat-sidenav
      [opened]="sidebarOpen()"
      mode="over"
      [disableClose]="!hasBackdrop()"
      (closed)="onSidebarClosed()"
      [autoFocus]="false"
    >
      <ng-content select="[slot=navbar]" />
    </mat-sidenav>

    <main class="pw-padding">
      <ng-content />
    </main>
  </mat-sidenav-container>
</mat-sidenav-container>
