import { Provider } from '@angular/core';
import {
  PriorityDialogsProvider,
  PriorityDialogService,
} from '@pwiz/priority/ui-data';
import { ePriorityType } from '@pwiz/entity/ts';
import { ImpactScoreDialogComponent } from '@pwiz/priority/impact/feature';
import {
  ConfidenceScoreDialogComponent,
  UrgencyScoreDialogComponent,
} from '@pwiz/priority/urgency/feature';
import { EffortScoreDialogComponent } from '@pwiz/priority/effort/feature';

const dialogAdapter = new PriorityDialogsProvider({
  [ePriorityType.impact]: ImpactScoreDialogComponent,
  [ePriorityType.urgency]: UrgencyScoreDialogComponent,
  [ePriorityType.confidence]: ConfidenceScoreDialogComponent,
  [ePriorityType.effort]: EffortScoreDialogComponent,
});
export const PRIORITY_DIALOG_SERVICE_PROVIDER: Provider[] = [
  { provide: PriorityDialogsProvider, useValue: dialogAdapter },
  PriorityDialogService,
];
