import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IItemSummary } from '@pwiz/item/summary/ts';
import {
  CurrencyComponent,
  PwLabelGroupComponent,
  PwLabelComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
  PwPassedTimePipe,
  SeeMoreComponent,
  QuarterComponent,
  FixedPipe,
  RectangleSkeletonComponent,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';
import { isItemFeature } from '@pwiz/item/ts';
import { getItemMaxEffort } from '@pwiz/priority/ts';
import { eItemStatus, ISettings } from '@pwiz/entity/ts';
import { ItemDescriptionComponent } from '@pwiz/jira/ui';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';

@Component({
  selector: 'pw-item-overview',
  standalone: true,
  imports: [
    CommonModule,
    ItemDescriptionComponent,
    PwLabelGroupComponent,
    CurrencyComponent,
    PwLabelComponent,
    PwLabelHeaderComponent,
    PwNullableValuePipe,
    PwPassedTimePipe,
    SeeMoreComponent,
    QuarterComponent,
    FixedPipe,
    RectangleSkeletonComponent,
    StatusSwitchCaseComponent
  ],
  templateUrl: './item-overview.component.html',
  styleUrl: './item-overview.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemOverviewComponent {
  $item = input.required<IItemSummary | null>({ alias: 'item' });
  $isDone = computed(() => this.$item()?.status === eItemStatus.done);
  $settings = input.required<ISettings | null>();
  fetchStatus =computed<PwCacheStatus>(() => {
    const item = this.$item();
    return item ? 'success' : 'loading';
  });
  $isFeature = computed(() => {
    const item = this.$item();
    return item ? isItemFeature(item) : null;
  });
  $maxEffort = computed(() => {
    const item = this.$item();
    const settings = this.$settings();
    return item && settings && getItemMaxEffort(item, settings);
  });
}
