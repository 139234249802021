import {
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import { CONFIDENCE_LEVELS, eConfidenceLevel } from '@pwiz/priority/ts';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import {
  BasicAutocompleteComponent,
  IdValuePairPipe,
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwSeparatedSectionComponent,
  PwSeparatedSectionHeaderComponent,
  RadioButtonComponent,
} from '@pwiz/infra/ui';
import {
  CONFIDENCE_THAT_REQUIRE_REASON,
  ConfidenceFg,
  ConfidenceForm,
  getConfidenceScores,
  IConfidenceScore,
} from '@pwiz/priority/urgency/ts';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AccountSelectComponent } from '@pwiz/account/ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { UiAccount } from '@pwiz/account/ts';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimeUtils } from '@pwiz/infra/ts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ItemValidation } from '@pwiz/entity/ts';
import { ValidationComponent } from './validation/validation.component';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import { KeyResultFormComponent } from './key-result-form/key-result-form.component';
import { KeyResultWithBusinessGoal } from '@pwiz/strategy/ts';

@Component({
  selector: 'pw-confidence-score-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    PwEnumToReadableStringPipe,
    RadioButtonComponent,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    AccountSelectComponent,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    PwSeparatedSectionComponent,
    PwSeparatedSectionHeaderComponent,
    PwDateTimePipe,
    BasicAutocompleteComponent,
    IdValuePairPipe,
    ValidationComponent,
    FormsModule,
    MatAutocompleteOrigin,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    KeyResultFormComponent,
  ],
  templateUrl: './confidence-score-form.component.html',
  styleUrls: ['./confidence-score-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  viewProviders: [provideParentControl()],
})
export class ConfidenceScoreFormComponent implements OnInit {
  urgencyLevels = getConfidenceScores(CONFIDENCE_LEVELS);
  #parentControl = useParentControlContainer<FormGroup<ConfidenceFg>>();

  link = new FormControl('');

  form = this.#initForm();
  $formValue = toSignal(this.form.valueChanges, {
    initialValue: this.form.value,
  });
  $isReasonRequired = computed(() => {
    const confidence = this.$formValue()?.confidence;
    return confidence
      ? CONFIDENCE_THAT_REQUIRE_REASON.includes(confidence)
      : false;
  });
  minDate = TimeUtils.addToDate(new Date(), { days: 1 });
  $maxDate = computed(
    () => this.$formValue().customerCommitmentDate || undefined,
  );
  value = input.required<IConfidenceScore>();

  accounts = input.required<UiAccount[]>();
  keyResultList = input.required<KeyResultWithBusinessGoal[]>();

  constructor() {
    this.#disableEnableExistsInCompetitors();
    this.#disableEnableInnovative();
    this.#disableEnableCustomerCommitments();
    this.#disableEnableCustomerReq();
    this.#disableEnableInternal();
    this.#disableEnablePaidFeature();
    this.#setConfidence();
    effect(
      () => {
        const { reason, ...value } = this.value();
        this.form.patchValue({
          ...value,
          reason: reason || '',
          keyResultList: [],
        });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.#parentControl.control.setControl('confidence', this.form);
  }

  #initForm(): FormGroup<ConfidenceForm> {
    const fb = inject(FormBuilder);
    return fb.group<ConfidenceForm>({
      avgCost: fb.control(null, [Validators.min(0)]),
      committedToCustomerIds: fb.control<string[] | null>(null),
      confidence: fb.control<eConfidenceLevel | null>(
        null,
        Validators.required,
      ),
      customerCommitmentDate: fb.control(null),
      existsInCompetitors: fb.control(null),
      innovative: fb.control(null),
      internalDeadLine: fb.control(null),
      isCustomerReq: fb.control(null),
      isInternal: fb.control(null),
      isPaidFeature: fb.control(null),
      numberOfPayingCustomers: fb.control(null, [Validators.min(0)]),
      reason: fb.control(''),
      validation: fb.control<ItemValidation[]>([]),
    });
  }

  #disableEnableExistsInCompetitors() {
    const $improvement = computed(() => this.$formValue().isInternal);
    const { existsInCompetitors } = this.form.controls;
    effect(
      () => this.#disableEnableControl($improvement(), existsInCompetitors),
      { allowSignalWrites: true },
    );
  }

  #disableEnableInnovative() {
    const $improvement = computed(() => this.$formValue().isInternal);
    const { innovative } = this.form.controls;
    effect(() => this.#disableEnableControl($improvement(), innovative), {
      allowSignalWrites: true,
    });
  }

  #disableEnableCustomerReq() {
    const $improvement = computed(() => this.$formValue().isInternal);
    const { isCustomerReq } = this.form.controls;
    effect(() => this.#disableEnableControl($improvement(), isCustomerReq), {
      allowSignalWrites: true,
    });
  }

  #disableEnableInternal() {
    const $isCustomerReq = computed(() => this.$formValue().isCustomerReq);
    const $isPaid = computed(() => this.$formValue().isPaidFeature);
    const $existsInCompetitors = computed(
      () => this.$formValue().existsInCompetitors,
    );
    const $innovative = computed(() => this.$formValue().innovative);
    const $customerCommitmentDate = computed(
      () => !!this.$formValue().customerCommitmentDate,
    );
    const $committedToCustomerIds = computed(
      () => (this.$formValue().committedToCustomerIds?.length || 0) > 0,
    );
    const { isInternal } = this.form.controls;
    effect(
      () =>
        this.#disableEnableControl(
          $isCustomerReq() ||
            $isPaid() ||
            $existsInCompetitors() ||
            $innovative() ||
            $customerCommitmentDate() ||
            $committedToCustomerIds(),
          isInternal,
        ),
      {
        allowSignalWrites: true,
      },
    );
  }

  #disableEnablePaidFeature() {
    const $improvement = computed(() => this.$formValue().isInternal);
    const $isPaid = computed(() => this.$formValue().isPaidFeature);
    const { isPaidFeature, avgCost, numberOfPayingCustomers } =
      this.form.controls;
    effect(
      () => {
        this.#disableEnableControl($improvement(), isPaidFeature);
        this.#disableEnableControl($improvement(), avgCost);
        this.#disableEnableControl($improvement(), numberOfPayingCustomers);
      },
      { allowSignalWrites: true },
    );
    effect(() => {
      this.#addValidators($isPaid(), avgCost, [Validators.required]);
      this.#addValidators($isPaid(), numberOfPayingCustomers, [
        Validators.required,
      ]);
    });
  }

  #disableEnableCustomerCommitments() {
    const $improvement = computed(() => this.$formValue().isInternal);
    const { customerCommitmentDate, committedToCustomerIds } =
      this.form.controls;
    effect(
      () => {
        this.#disableEnableControl($improvement(), customerCommitmentDate);
        this.#disableEnableControl($improvement(), committedToCustomerIds);
      },
      { allowSignalWrites: true },
    );
  }

  #disableEnableControl(
    disable: boolean | null | undefined,
    control: FormControl,
  ): void {
    if (disable) {
      control.disable();
    } else {
      control.enable();
    }
  }

  #addValidators(
    add: boolean | null | undefined,
    control: FormControl,
    validators: ValidatorFn[],
  ) {
    if (add) {
      control.addValidators(validators);
    } else {
      control.removeValidators(validators);
    }
  }

  #setConfidence() {
    const $isDevFeature = computed(() => this.$formValue().isInternal);

    effect(
      () => {
        if ($isDevFeature() && !this.form.controls.confidence.value) {
          this.form.controls.confidence.setValue(eConfidenceLevel.low);
        }
      },
      { allowSignalWrites: true },
    );
  }
}
