import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  input,
} from '@angular/core';
import { PwDrawerService } from '@pwiz/infra/drawer';
import {
  ItemNestedTicketService,
  TicketDrawerComponent,
} from '@pwiz/ticket/feature';
import { useDrawerRoute } from './use-drawer-route';
import { TicketTableDataAdapter } from '@pwiz/ticket/ui-data';

@Component({
  selector: 'pw-item-nested-tickets',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
  providers: [
    {
      provide: TicketTableDataAdapter,
      useExisting: ItemNestedTicketService,
    },
  ],
})
export class ItemNestedTicketsSidebarRouterComponent {
  #drawer = inject(PwDrawerService);
  #itemNestedTicketsService = inject(ItemNestedTicketService);
  #injector = inject(Injector);
  itemId = input.required<string>();

  constructor() {
    useDrawerRoute(this.itemId, this.open.bind(this));
    effect(() => {
      const itemId = this.itemId();
      if (itemId) {
        this.#itemNestedTicketsService.getTickets$(itemId).subscribe();
      }
    });
  }

  open(itemId: string) {
    this.#drawer.open(TicketDrawerComponent, this.#injector, {
      data: { itemId },
      name: 'Tickets',
    });
  }
}
