import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  ImpactViewComponent,
  PriorityScoreDialogContainerComponent,
  PriorityScoreDialogHeaderComponent,
  PriorityScoreSubmitContainerComponent,
  PrioritySummaryComponent,
} from '@pwiz/priority/ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UrgencyScoreService } from '@pwiz/priority/urgency/ui-data';
import {
  DialogContentComponent,
  DialogFooterComponent,
  DialogFooterDirective,
  DialogHeaderComponent,
  DialogV3Component,
  useCloseDialogFinished,
  useDialogData,
} from '@pwiz/infra/dialog';
import { PriorityScoreDialogData, UiPriority } from '@pwiz/priority/ts';
import {
  ConfidenceFg,
  IConfidenceFv,
  mapPriorityToConfidenceFv,
} from '@pwiz/priority/urgency/ts';
import { PriorityHelperService } from '@pwiz/priority/ui-data';
import { ConfidenceScoreFormComponent } from '@pwiz/priority/urgency/ui';
import { $useAccounts } from '@pwiz/account/ui-data';
import { $useUserList } from '@pwiz/user/ui-data';
import { StrategyService } from '@pwiz/strategy/ui-data';
import { toSignal } from '@angular/core/rxjs-interop';
import { $useSettings } from '@pwiz/settings/ui-data';

@Component({
  selector: 'pw-confidece-score-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    PriorityScoreDialogContainerComponent,
    ReactiveFormsModule,
    PrioritySummaryComponent,
    PriorityScoreSubmitContainerComponent,
    PriorityScoreDialogHeaderComponent,
    ConfidenceScoreFormComponent,
    DialogHeaderComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DialogFooterDirective,
    ImpactViewComponent,
    DialogV3Component,
  ],
  templateUrl: './confidence-score-dialog.component.html',
  styleUrls: ['./confidence-score-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PriorityHelperService, UrgencyScoreService],
})
export class ConfidenceScoreDialogComponent {
  #dialogFinished = useCloseDialogFinished();
  #urgencyScoreService = inject(UrgencyScoreService);
  #priorityHelper = inject(PriorityHelperService);
  #partialPriority = useDialogData<PriorityScoreDialogData>().priority;
  #strategyService = inject(StrategyService);

  $accounts = $useAccounts();
  form = new FormGroup<ConfidenceFg>({} as ConfidenceFg);
  saving = signal(false);
  $priority = this.#urgencyScoreService.$getPriority(this.#partialPriority);
  $item = toSignal(this.#priorityHelper.$getItem(this.$priority), {
    initialValue: null,
  });
  $assignees = this.#priorityHelper.$priorityLead(this.$priority);
  $users = $useUserList();
  $keyResults = toSignal(this.#strategyService.getKeyResultList(), {
    requireSync: true,
  });
  settings = $useSettings();
  $confidenceForm = computed(() =>
    mapPriorityToConfidenceFv(this.settings(), this.$priority()),
  );

  constructor() {
    // effect(
    //   () => {
    //     if (confidence.keyResultList.length) {
    //       confidence.keyResultList.forEach((keyResultTarget) =>
    //         this.form.controls.confidence.controls.keyResultList?.push(
    //           createConfidenceKeyResultFormRow(
    //             keyResultTarget.keyResultId,
    //             keyResultTarget.target,
    //           ),
    //         ),
    //       );
    //     }
    //   },
    //   { allowSignalWrites: true },
    // );
  }

  onSubmit() {
    const { confidence } = this.form.value;
    if (!this.form.valid || !confidence) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.saving.set(true);
    this.#urgencyScoreService
      .updateConfidence(
        this.$priority() as UiPriority,
        confidence as IConfidenceFv,
      )
      .subscribe(() => this.#dialogFinished({ ok: true, response: null }));
  }
}
