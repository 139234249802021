import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerHeaderComponent,
} from '@pwiz/infra/drawer';
import { TicketTableSmartComponent } from '../ticket-table';

@Component({
  selector: 'pw-ticket-drawer',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    PwDrawerHeaderComponent,
    PwDrawerContentComponent,
    TicketTableSmartComponent,
  ],
  template: `
    <pw-drawer-container size="m">
      <pw-drawer-header> Ticket Summary </pw-drawer-header>
      <pw-drawer-content>
        <pw-ticket-table-smart />
      </pw-drawer-content>
    </pw-drawer-container>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDrawerComponent {}
