import { computed, inject, Signal } from '@angular/core';
import { UserMgmtService } from './user-mgmt.service';
import { PwFetchOptions } from '@pwiz/infra/cache/ts';
import { toSignal } from '@angular/core/rxjs-interop';

export function $useUserList(options?: PwFetchOptions) {
  const $users = toSignal(inject(UserMgmtService).getUserList$(options), {
    requireSync: true,
  });
  return computed(() => {
    const { data, status } = $users();
    if (status === 'success') {
      return data;
    }
    return null;
  });
}
