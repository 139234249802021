import { inject, Injectable } from '@angular/core';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { USER_MGMT_API, USER_MGMT_API_ENDPOINTS, PwUser } from '@pwiz/user/ts';
import { HttpClient } from '@angular/common/http';
import {
  pwCacheUpdateEntityArr,
  PwCacheList,
  PwFetchOptions,
} from '@pwiz/infra/cache/ts';

@Injectable({
  providedIn: 'root',
})
export class UserMgmtService {
  #url = useBaseApiUrl(USER_MGMT_API);
  #http = inject(HttpClient);
  #usersCache = new PwCacheList<PwUser>(
    null,
    this.#getUserList$(),
    pwCacheUpdateEntityArr,
  );

  #getUserList$() {
    return this.#http.get<PwUser[]>(
      `${this.#url}/${USER_MGMT_API_ENDPOINTS.userList}`,
    );
  }

  getUserList$(options?: PwFetchOptions) {
    return this.#usersCache.getV2$(options);
  }
}
