<p>
  Customer impact info:
  @if ($isInternal()) {
    <span class="danger">Internal</span>
  } @else if (!$hasCustomers()) {
    <span>Not linked to customers</span>
  }
</p>
@if ($isAutoRated()) {
  <span class="danger">* Rated automatically</span>
}
<pw-label-group card inLine style="font-size: 14px;">
  <pw-label>
    <pw-label-header># Customers</pw-label-header>
    {{ $impact()?.accountPriority?.length  | pwNullableValue: '0'}}
  </pw-label>
  <pw-label>
    <pw-label-header>Deal breakers</pw-label-header>
    {{ $impactGroups()?.dealBreaker?.length | pwNullableValue: '0'}}
  </pw-label>
  <pw-label>
    <pw-label-header>Total ARR</pw-label-header>
    <pw-currency [amount]="$arr()"/>
  </pw-label>
</pw-label-group>

@if ($isBug()) {
  <div class="container">
    <div class="row">
      <span class="text">Deal breaker</span>
      <pw-customer-list [accountList]="$impactGroups()?.dealBreaker || []" />
      <pw-currency [amount]="$impactGroups()?.dealBreaker | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Frustrated</span>
      <pw-customer-list [accountList]="$impactGroups()?.frustrated || []" />
      <pw-currency [amount]="$impactGroups()?.frustrated | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Dissatisfied</span>
      <pw-customer-list [accountList]="$impactGroups()?.dissatisfied || []" />
      <pw-currency [amount]="$impactGroups()?.dissatisfied | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Slightly</span>
      <pw-customer-list [accountList]="$impactGroups()?.slightly || []" />
      <pw-currency [amount]="$impactGroups()?.slightly | pwSumArr" class="arr"/>
    </div>
  </div>
} @else {
  <div class="container">
    <div class="row">
      <span class="text">Deal breaker</span>
      <pw-customer-list [accountList]="$impactGroups()?.dealBreaker || []" />
      <pw-currency [amount]="$impactGroups()?.dealBreaker | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Must have</span>
      <pw-customer-list [accountList]="$impactGroups()?.frustrated || []" />
      <pw-currency [amount]="$impactGroups()?.frustrated | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Nice to have</span>
      <pw-customer-list [accountList]="$impactGroups()?.dissatisfied || []" />
      <pw-currency [amount]="$impactGroups()?.dissatisfied | pwSumArr" class="arr"/>
    </div>
    <div class="row">
      <span class="text">Low</span>
      <pw-customer-list [accountList]="$impactGroups()?.slightly || []" />
      <pw-currency [amount]="$impactGroups()?.slightly | pwSumArr" class="arr"/>
    </div>
  </div>
}
