import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'pw-internal-item-marker',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './internal-item-marker.component.html',
  styleUrls: ['./internal-item-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalItemMarkerComponent {
  @Input({ required: true })
  type!: 'bug' | 'feature';
}
