import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITicketDescription } from '@pwiz/entity/ts';
import { JiraDescriptionTextComponent } from './jira/description/jira-description-text.component';
import { JiraDescriptionListComponent } from './jira/list/jira-description-list.component';

@Component({
  selector: 'pw-item-description',
  standalone: true,
  imports: [
    CommonModule,
    JiraDescriptionTextComponent,
    JiraDescriptionListComponent,
  ],
  templateUrl: './item-description.component.html',
  styleUrl: './item-description.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-item-description',
  },
})
export class ItemDescriptionComponent {
  $descriptionObject = input.required<ITicketDescription | null>({
    alias: 'descriptionObject',
  });

  $descriptionText = input.required<string | null>({
    alias: 'descriptionText',
  });
}
