import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  inject,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CurrencyComponent,
  ExternalLinkComponent,
  PwEnumToReadableStringPipe,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { ItemPriorityComponent, PwItemTimeToDeliverPipe } from '@pwiz/item/ui';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ITicketTableColumn, ITicketTableRow } from '@pwiz/ticket/ts';
import { TicketIdComponent } from '../ticket-id/ticket-id.component';
import {
  TicketTableActionDirective,
  TicketTableIconDirective,
} from './directive';
import { MatIconButton } from '@angular/material/button';
import { toObservable } from '@angular/core/rxjs-interop';
import { DrawerRoutingService } from '@pwiz/infra/drawer';

@Component({
  selector: 'pw-ticket-table',
  standalone: true,
  imports: [
    CommonModule,
    ExternalLinkComponent,
    ItemPriorityComponent,
    MatTableModule,
    PwEnumToReadableStringPipe,
    PwNullableValuePipe,
    CurrencyComponent,
    TicketIdComponent,
    PwItemTimeToDeliverPipe,
    MatIconButton,
  ],
  templateUrl: './ticket-table.component.html',
  styleUrl: './ticket-table.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTableComponent {
  dataSource = new MatTableDataSource();
  #draws = inject(DrawerRoutingService);

  $tickets = input.required<ITicketTableRow[]>({ alias: 'tickets' });

  $displayColumns = input.required<ITicketTableColumn[]>({
    alias: 'displayColumns',
  });

  $action = contentChild<TicketTableActionDirective>(
    TicketTableActionDirective,
  );
  $ticketIconTemplate = contentChild<TicketTableIconDirective>(
    TicketTableIconDirective,
  );

  constructor() {
    toObservable(this.$tickets).subscribe(
      (res) => (this.dataSource.data = res),
    );
  }

  onSummaryClick(ticket: ITicketTableRow) {
    this.#draws.ticketSummary(ticket.id);
  }
}
