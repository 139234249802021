<ng-container [formGroup]="form">
  <div
    class="impact-levels-container main-form-container"
    formArrayName="clientsImpact"
  >
    <div class="connect-crm-message">
      <h3 class="popup-message">
        {{ connectCrmMessage }}
      </h3>
    </div>
    @for (row of form.controls.clientsImpact.controls; track row) {
      <ng-container [formGroup]="row">
        @if (
          row.value.impact !== null && row.value.impact !== undefined && $impactLevelDisplayMap()[row.value.impact]
        ) {
          <div
            class="impact-level colorfully-label pw-flex pw-center"
            [ngClass]="{
              filled:
                (row.controls.customerIds.value?.length || 0) > 0 ||
                ($ratedWithNoCustomers() &&
                  $initialImpactScore() === row.value.impact)
            }"
          >
            <p>
              {{
                $impactLevelDisplayMap()[row.value.impact]
                  | pwEnumToReadableString
              }}
            </p>
          </div>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Attach customers</mat-label>
            <pw-basic-select-search
              formControlName="customerIds"
              [multiple]="true"
              [data]="clientListMap[row.value.impact]()"
              placeholder="Attach customers"
            />
          </mat-form-field>
          <div>
            Related ARR: <pw-currency [amount]="$impactArr()[row.value.impact]" />
          </div>
        }
      </ng-container>
    }
  </div>

  <div class="info-container pw-flex pw-column">
    <ng-content select="[slot=info-header]" />
    <mat-form-field class="reason pw-flex-1">
      <mat-label> Add Reason </mat-label>
      <textarea
        [required]="$isReasonRequired()"
        matInput
        [formControl]="form.controls.reason"
      ></textarea>
      <mat-error>Why is the item a deal breaker?</mat-error>
    </mat-form-field>
    <ng-content select="[slot=info-footer]" />
  </div>
</ng-container>
