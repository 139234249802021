import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriorityOverviewComponentInterface } from '../priority-overview-component-interface';
import { UiPriority } from '@pwiz/priority/ts';
import { IItemSummary } from '@pwiz/item/summary/ts';
import {
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
} from '@pwiz/infra/ui';
import { PwUser } from '@pwiz/user/ts';
import {
  PriorityConfidenceOverviewComponent,
  PriorityUrgencyOverviewComponent,
} from '@pwiz/priority/ui';

@Component({
  selector: 'pw-urgency-overview',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelComponent,
    PwDateTimePipe,
    PwEnumToReadableStringPipe,
    PriorityUrgencyOverviewComponent,
    PriorityConfidenceOverviewComponent,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <pw-priority-urgency-overview
      [users]="users()"
      [isReadOnly]="isReadOnly()"
      [priority]="priority()"
      (updatePriority)="updatePriority()()"
    />
  `,
})
export class UrgencyOverviewComponent
  implements PriorityOverviewComponentInterface
{
  item = input.required<IItemSummary>();
  priority = input.required<UiPriority>();
  users = input.required<PwUser[] | null>();
  isReadOnly = input.required<boolean>();
  updatePriority = input.required<() => void>();
}
