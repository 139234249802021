import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ImpactScoreFormComponent,
  ItemImpactScoreConnectCrmMessageComponent,
  PriorityScoreIsInternalItemComponent,
} from '@pwiz/priority/impact/ui';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  calculateSelectedClientsMrr,
  ClientsImpactFormValue,
  getClientsFromImpactForm,
  ImpactFormContainerForm,
  ImpactScoreForm,
} from '@pwiz/priority/impact/ts';
import { MatButtonModule } from '@angular/material/button';
import { PwFormData } from '@pwiz/infra/form';
import { PriorityImpactService } from '@pwiz/priority/impact/ui-data';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CurrencyComponent,
  HighlightedChipComponent,
  PwDateTimePipe,
  PwNullableValuePipe,
  PwOnInit$,
} from '@pwiz/infra/ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PriorityHelperService } from '@pwiz/priority/ui-data';
import {
  InternalItemMarkerComponent,
  PriorityAutoRatedPipe,
  PriorityScoreDialogContainerComponent,
  PriorityScoreDialogHeaderComponent,
  PriorityScoreFormContainerComponent,
  PriorityScoreFormContainerDirective,
  PriorityScoreItemSummeryContainerDirective,
  PriorityScoreSubmitContainerComponent,
  PrioritySkippedMarkerComponent,
  PrioritySummaryComponent,
} from '@pwiz/priority/ui';
import { MatIconModule } from '@angular/material/icon';
import { useCloseDialogFinished, usePwToaster } from '@pwiz/infra/dialog';
import {
  getPrioritySkipped,
  mapImpactScoreToEnum,
  UiPriority,
} from '@pwiz/priority/ts';
import { $getFormValue } from '@pwiz/infra/form-2/ui';
import { eItemType, ePriorityStatus } from '@pwiz/entity/ts';
import { toSignal } from '@angular/core/rxjs-interop';
import { $useUserList } from '@pwiz/user/ui-data';

@Component({
  selector: 'pw-impact-score-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyComponent,
    ImpactScoreFormComponent,
    MatButtonModule,
    FormsModule,
    MatTooltipModule,
    ItemImpactScoreConnectCrmMessageComponent,
    PriorityScoreDialogContainerComponent,
    PriorityScoreFormContainerDirective,
    PriorityScoreItemSummeryContainerDirective,
    PriorityScoreSubmitContainerComponent,
    PriorityScoreFormContainerComponent,
    HighlightedChipComponent,
    PriorityScoreDialogHeaderComponent,
    PrioritySummaryComponent,
    PriorityScoreIsInternalItemComponent,
    MatIconModule,
    InternalItemMarkerComponent,
    ReactiveFormsModule,
    PwDateTimePipe,
    PwNullableValuePipe,
    PrioritySkippedMarkerComponent,
    PriorityAutoRatedPipe,
  ],
  templateUrl: './impact-score-dialog.component.html',
  styleUrls: ['./impact-score-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PriorityImpactService, PriorityHelperService],
})
export class ImpactScoreDialogComponent extends PwOnInit$ {
  #toaster = usePwToaster();

  #priorityImpactService = inject(PriorityImpactService);
  #priorityHelper = inject(PriorityHelperService);

  #close = useCloseDialogFinished<{ skipToaster?: boolean }>();

  form = new FormGroup<ImpactFormContainerForm>({} as ImpactFormContainerForm);

  allClients = this.#priorityImpactService.accounts();
  $users = $useUserList();
  isSaving = signal(false);

  hasCrmConnected = this.#priorityImpactService.hasCrmConnected();
  #partialPriority = inject<{ priority: UiPriority }>(MAT_DIALOG_DATA).priority;
  $priority = this.#priorityHelper.$getPriority(this.#partialPriority);
  $canSkip = this.$getCanSkip();
  $isFeature = this.#isFeature();
  $isInternal = computed(() =>
    this.#priorityImpactService.getIsInternalItem(this.$priority()),
  );

  assignees$ = this.#priorityHelper.$priorityLead(this.$priority);

  $formValue = $getFormValue(this.form);

  $isSkipped = computed(() => getPrioritySkipped(this.$priority()));

  $initialValue = computed(() =>
    this.$priority().score
      ? mapImpactScoreToEnum(this.$priority().score!)
      : null,
  );

  $ratedWithNoCustomers = computed(() => {
    const { status, accountPriority, score } = this.$priority();
    return (
      status === ePriorityStatus.done &&
      score != null &&
      (!accountPriority || accountPriority.length === 0)
    );
  });

  $selectedCustomers = computed(() =>
    getClientsFromImpactForm(
      this.$formValue().impactScore as PwFormData<ClientsImpactFormValue>,
    ),
  );

  $totalArr = computed(() =>
    calculateSelectedClientsMrr(this.allClients(), this.$selectedCustomers()),
  );

  constructor() {
    super();
    const val$ = this.#priorityImpactService.getItemImpactValues(
      this.#partialPriority,
    );
    val$.subscribe();
    this.onInit$(() => val$).subscribe((val) =>
      this.form.patchValue({ impactScore: val }),
    );
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.saveImpactScore();
      return;
    }
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  saveImpactScore() {
    this.isSaving.set(true);
    this.#priorityImpactService
      .saveImpactScore(
        this.$priority(),
        this.form.value.impactScore as ImpactScoreForm,
      )
      .subscribe(() => {
        this.#close({ ok: true, response: {} });
      });
  }

  $getCanSkip() {
    const $formValue = toSignal(this.form.valueChanges, {
      initialValue: this.form.value,
    });
    return computed(() => {
      const { impactScore } = $formValue();
      if (!impactScore) {
        return false;
      }
      const { clientsImpact } = impactScore;
      return !clientsImpact?.some(
        (client) => (client.customerIds?.length || 0) > 0,
      );
    });
  }

  #isFeature() {
    return computed(() => {
      const { type } = this.$priority().item || this.#partialPriority.item;
      return type === eItemType.feature;
    });
  }

  skip() {
    this.isSaving.set(true);
    this.#priorityImpactService.skipImpact(this.$priority()).subscribe({
      next: () => this.#toaster.successes('Impact priority skipped'),
      error: () => {
        this.#toaster.error('Something went wrong, please try again');
      },
      complete: () =>
        this.#close({ ok: true, response: { skipToaster: true } }),
    });
  }
}
