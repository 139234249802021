import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { toSignal } from '@angular/core/rxjs-interop';
import { ItemValidation } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-validation',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatButton,
    MatLabel,
    MatIcon,
  ],
  templateUrl: './validation.component.html',
  styleUrl: './validation.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'pw-flex pw-gap',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ValidationComponent,
      multi: true,
    },
  ],
})
export class ValidationComponent implements ControlValueAccessor {
  #onChange?: (value: ItemValidation[]) => void;
  #onTouched?: () => void;
  $value = signal<ItemValidation[]>([]);
  form = this.#initForm();
  $formValue = toSignal(this.form.valueChanges, {
    initialValue: this.form.value,
  });
  constructor() {
    effect(() => {
      this.#onChange?.(this.$value());
    });
    this.#validate();
  }

  writeValue(value: ItemValidation[]): void {
    this.$value.set(value);
  }

  registerOnChange(fn: (value: ItemValidation[]) => void): void {
    this.#onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group({
      name: fb.control(''),
      link: fb.control(''),
    });
  }

  #validate() {
    const $link = computed(() => this.$formValue().link);
    const $name = computed(() => this.$formValue().name);
    effect(
      () => {
        if ($link() || $name()) {
          this.form.controls.name.setValidators([Validators.required]);
          this.form.controls.link.setValidators([Validators.required]);
        } else {
          this.form.controls.name.setValidators([]);
          this.form.controls.link.setValidators([]);
        }
        this.form.updateValueAndValidity();
      },
      { allowSignalWrites: true },
    );
  }

  protected readonly onsubmit = onsubmit;

  onSubmit() {
    if (this.form.valid) {
      this.$value.update((list) => [
        this.form.value as ItemValidation,
        ...list,
      ]);
      this.form.reset();
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  onFocus() {
    this.#onTouched?.();
  }

  removeLink(index: number) {
    this.$value.update((list) => {
      list.splice(index, 1);
      return list;
    });
  }
}
