<div class="container">
  <h4 class="pw-title pw-flex-1">
    <ng-content />
  </h4>
  <p class="pw-flex-1 pw-flex summary-container pw-gap">
    <strong>Item summary:</strong>
    <span class="pw-flex-1">
      {{ issue()?.summery | pwNullableValue }}
    </span>
  </p>
</div>
@if (issue(); as issue) {
  <div class="description">
    <strong>Description:</strong>
    <pw-item-description
      [descriptionObject]="issue.descriptionObject"
      [descriptionText]="issue.descriptionText"
    />
    <a [href]="issue.url" target="_blank">
      View more in jira
    </a>
  </div>
}
