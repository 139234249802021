import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  input,
} from '@angular/core';
import { useDrawerRoute } from './use-drawer-route';
import { PwDrawerService } from '@pwiz/infra/drawer';
import { TicketSummaryComponent } from '@pwiz/ticket/feature';

@Component({
  selector: 'pw-ticket-summary',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
})
export class TicketSummarySidebarRouteComponent {
  #drawer = inject(PwDrawerService);
  #injector = inject(Injector);
  ticketId = input.required<string>();

  constructor() {
    useDrawerRoute(this.ticketId, this.open.bind(this));
  }

  open(ticketId: string): void {
    this.#drawer.open(TicketSummaryComponent, this.#injector, {
      data: { ticketId },
      name: 'Ticket Summary',
    });
  }
}
