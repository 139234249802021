import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, ReplaySubject, switchMap } from 'rxjs';

@Component({
  selector: 'pw-on-after-view-init',
  standalone: true,
  imports: [CommonModule],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PwOnInit$ implements OnInit, OnDestroy {
  private _onInit$ = new ReplaySubject<void>(1);
  ngOnInit(): void {
    this._onInit$.next();
  }

  ngOnDestroy() {
    this._onInit$.complete();
  }
  onInit$<T>(obs: () => Observable<T>) {
    return this._onInit$.asObservable().pipe(switchMap(obs));
  }
}
