import { inject, Injectable, signal } from '@angular/core';
import { ITicket } from '@pwiz/entity/ts';
import { ITicketTableColumn } from '@pwiz/ticket/ts';
import { map, Observable } from 'rxjs';
import { UiItem } from 'infra/ui-entity';
import { TicketService, TicketTableDataAdapter } from '@pwiz/ticket/ui-data';
import { $useTmsProviderUserIdValueList } from '@pwiz/provider-user/ui-data';

@Injectable({
  providedIn: 'root',
})
export class ItemNestedTicketService implements TicketTableDataAdapter {
  #ticketService = inject(TicketService);
  $availableUsers = $useTmsProviderUserIdValueList();
  displayColumns = signal<ITicketTableColumn[]>([
    'action',
    'id',
    'summary',
    'board',
    'assignee',
    'status',
    'estimation',
    'cost',
  ]);
  item?: UiItem;

  tickets$!: Observable<ITicket[]>;

  getTickets$(itemId: string) {
    this.tickets$ = this.#ticketService
      .getByItem(itemId)
      .pipe(map((cache) => cache.data || []));
    return this.tickets$;
  }
}
