<ng-container [formGroup]="form">

  <mat-form-field appearance="outline"  subscriptSizing="dynamic">
    <mat-label>Priority</mat-label>
    <mat-select formControlName="priority" multiple placeholder="All Priorities">
      <mat-option *ngFor="let priority of itemPriorities" [value]="priority">{{priority | pwEnumToReadableString}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Type</mat-label>
    <mat-select formControlName="type" placeholder="All Types">
      <mat-option [value]="null">
        All
      </mat-option>
      @for (itemType of itemTypes;track itemType) {
        <mat-option [value]="itemType">
        <span class="pw-flex pw-center-vr pw-gap-l">
          {{itemType | pwEnumToReadableString}} <pw-item-type-icon [type]="itemType"/>
        </span>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"  subscriptSizing="dynamic">
    <mat-label>Status</mat-label>
    <mat-select formControlName="status" multiple placeholder="All Statuses">
      <mat-option *ngFor="let status of statuses" [value]="status">{{status | pwEnumToReadableString}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"  subscriptSizing="dynamic">
    <pw-basic-select-search formControlName="assignee" [multiple]="true" [data]="users()" placeholder="Assignee" searchInputPlaceholder="Search users..."/>
  </mat-form-field>

</ng-container>

