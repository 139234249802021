import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CurrencyComponent,
  ExternalLinkComponent,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwLabelGroupComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import {
  getIssueParent,
  getTicketActualMetrics,
  getTicketItem,
  isTicketDone,
} from '@pwiz/ticket/ts';
import { ITicket } from '@pwiz/entity/ts';
import { DrawerLinkDirective } from '@pwiz/infra/drawer';
import { ItemDescriptionComponent } from '@pwiz/jira/ui';

@Component({
  selector: 'pw-ticket-summary-ui',
  standalone: true,
  imports: [
    CommonModule,
    PwLabelGroupComponent,
    PwLabelComponent,
    PwLabelHeaderComponent,
    PwEnumToReadableStringPipe,
    PwNullableValuePipe,
    ExternalLinkComponent,
    CurrencyComponent,
    ItemDescriptionComponent,
    DrawerLinkDirective,
  ],
  templateUrl: './ticket-summary-ui.component.html',
  styleUrl: './ticket-summary-ui.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketSummaryUiComponent {
  $ticket = input.required<ITicket | null>({ alias: 'ticket' });

  $parent = computed(() =>
    this.$ticket() ? getIssueParent(this.$ticket()!) : null,
  );

  $item = computed(() =>
    this.$ticket() ? getTicketItem(this.$ticket()!) : null,
  );

  $averageEngineerCost = input.required<number | null>({
    alias: 'averageEngineerCost',
  });

  $isDone = computed(() =>
    this.$ticket() ? isTicketDone(this.$ticket()!) : null,
  );

  $effortEstimationCost = computed(() => {
    const ticket = this.$ticket();
    if (!ticket) {
      return null;
    }
    return (ticket.effortEstimation || 0) * (this.$averageEngineerCost() || 0);
  });

  $actual = computed(() => {
    const ticket = this.$ticket();
    if (!ticket) {
      return null;
    }
    const effort = getTicketActualMetrics(ticket);
    if (effort == null) {
      return null;
    }
    const cost = effort * (this.$averageEngineerCost() || 0);
    return {
      effort,
      cost,
    };
  });

  @Output()
  parentClick = new EventEmitter<{ ticket: ITicket }>();
}
