<mat-form-field
  matAutocompleteOrigin
  subscriptSizing="dynamic"
  #krOrigin="matAutocompleteOrigin"
  class="key-result-select"
>
  <mat-label>Add Key Result</mat-label>
  <pw-basic-autocomplete
    #keyResultAutocomplete
    [options]="$unselectedKeyResultList()"
    placeholder="Key result (Objective, Priority)"
    (selectionChange)="keyResultSelected($event, keyResultAutocomplete)"
    value=""
  />
</mat-form-field>

@if ($formValue().length > 0) {
  <div class="table-container">
    <table mat-table [dataSource]="dataSource$" class="small-form-fields">
    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef>Objective Priority</th>
      <td mat-cell *matCellDef="let row; let i = index">
        P{{$selectedKeyResultList()[i]?.businessGoal?.priority}}.
      </td>
    </ng-container>

    <ng-container matColumnDef="businessGoal">
      <th mat-header-cell *matHeaderCellDef>Objective</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{$selectedKeyResultList()[i]?.businessGoal?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="main">
      <th mat-header-cell *matHeaderCellDef>Focus</th>
      <td mat-cell *matCellDef="let i = index">
        {{i === 0 ? 'Main' : 'Secondary'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="keyResult">
      <th mat-header-cell *matHeaderCellDef>Key Result</th>
      <td mat-cell *matCellDef="let row; let i = index">
        {{$selectedKeyResultList()[i]?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef>Feature Target in KR (%)</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-form-field class="target" floatLabel="auto" subscriptSizing="dynamic">
          <input
            matInput
            type="number"
            placeholder="Expected target"
            [formControl]="form.controls[i].controls.target"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let i = index">
        <button mat-icon-button type="button" (click)="remove(i)"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
  </div>
}
