import { eConfidenceLevel, UiPriority } from '@pwiz/priority/ts';

const map: Record<eConfidenceLevel, string> = {
  [eConfidenceLevel.very_high]: 'Very high 100%',
  [eConfidenceLevel.high]: 'High 80%',
  [eConfidenceLevel.medium]: 'Medium 60%',
  [eConfidenceLevel.low]: 'Low 40%',
  [eConfidenceLevel.very_low]: 'Very low 20%',
};
export function getConfidenceDisplayString({ score }: UiPriority) {
  return map[score as eConfidenceLevel];
}
