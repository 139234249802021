import { Observable } from 'rxjs';
import { UiItem } from 'infra/ui-entity';
import { ITicketTableColumn, ITicketTableFilter } from '@pwiz/ticket/ts';
import { MappedQueryParams } from '@pwiz/infra/query-params/data';
import { Signal } from '@angular/core';
import { IdValuePair } from '@pwiz/infra/ts';
import { ITicket } from '@pwiz/entity/ts';

export abstract class TicketTableDataAdapter {
  abstract tickets$: Observable<ITicket[]>;
  abstract displayColumns: Signal<ITicketTableColumn[]>;
  abstract filters?: MappedQueryParams<ITicketTableFilter>;
  abstract $availableUsers: Signal<IdValuePair[]>;
  item?: UiItem;
}
