import { IPriority } from '@pwiz/entity/ts';
import { UrgencyScoreFormValue } from '../type';
import { mapUrgencyScoreToLevel, UrgencyScore } from '@pwiz/priority/ts';

export function toUrgencyScoreForm(priority: IPriority): UrgencyScoreFormValue {
  return {
    isInternal: priority?.item?.isInternal ?? false,
    urgency:
      priority.score != null
        ? mapUrgencyScoreToLevel(priority.score as UrgencyScore)
        : undefined,
    reason: priority.reason || null,
  };
}
