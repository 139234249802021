import {
  mapUrgencyLevelToScore,
  mapUrgencyScoreToDisplayValue,
  UrgencyLevel,
} from '@pwiz/priority/ts';
import { IdValuePair } from '@pwiz/infra/ts';

export function getUrgencyScores(
  urgencyScores: Readonly<UrgencyLevel[]>,
): IdValuePair[] {
  return urgencyScores.map((urgencyLevel) => {
    return {
      id: urgencyLevel,
      value:
        mapUrgencyScoreToDisplayValue(mapUrgencyLevelToScore(urgencyLevel)) ||
        '',
    };
  });
}
