import {
  ConfidenceScore,
  mapConfidenceScoreToEnum,
  UiPriority,
} from '@pwiz/priority/ts';
import { IConfidenceScore } from '../type';
import { IItem, ISettings } from '@pwiz/entity/ts';
import { getLastDayOfBusinessQuarter } from '@pwiz/settings/ts';

export function mapPriorityToConfidenceFv(
  settings: ISettings,
  priority: UiPriority,
): IConfidenceScore {
  const score = (priority.score as ConfidenceScore)!;
  return {
    reason: priority.reason,
    committedToCustomerIds:
      priority.item.committedToCustomers?.map((account) => account.id) || null,
    confidence: mapConfidenceScoreToEnum(score),
    innovative: priority.item.innovative,
    existsInCompetitors: priority.item.existsInCompetitors,
    isInternal: priority.item.isInternal || false,
    avgCost: priority.item.avgCost,
    isCustomerReq: priority.item.isCustomerReq || false,
    isPaidFeature: priority.item.isPaidFeature,
    numberOfPayingCustomers: priority.item.numberOfPayingCustomers,
    internalDeadLine: getDeadline(settings, priority.item),
    keyResultList: getKeyResults(priority.item),
    customerCommitmentDate: priority.item.customerCommitmentDate
      ? new Date(priority.item.customerCommitmentDate)
      : null,
    validation: priority.item.metadata?.validation || [],
  };
}

function getDeadline(
  settings: ISettings,
  { internalDeadLine, deadlineQ, deadlineYear }: IItem,
) {
  if (internalDeadLine) {
    return internalDeadLine;
  }
  if (deadlineYear) {
    return getLastDayOfBusinessQuarter(settings, {
      year: deadlineYear,
      q: deadlineQ,
    });
  }
  return null;
}

function getKeyResults({ keyResultTargetList, strategicInitiative }: IItem) {
  if (keyResultTargetList && keyResultTargetList.length) {
    return keyResultTargetList.map(({ keyResult, target }) => ({
      keyResultId: keyResult.id,
      target,
    }));
  }
  if (strategicInitiative?.keyResultList && strategicInitiative.keyResultList) {
    return strategicInitiative.keyResultList.map((keyResult) => ({
      keyResultId: keyResult.id,
      target: null,
    }));
  }
  return [];
}
