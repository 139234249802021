import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  getUrgencyScores,
  URGENCIES_THAT_REQUIRE_REASON,
  UrgencyContainerForm,
  UrgencyFormGroup,
} from '@pwiz/priority/urgency/ts';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  PwEnumToReadableStringPipe,
  RadioButtonComponent,
} from '@pwiz/infra/ui';
import { URGENCY_LEVEL, UrgencyLevel } from '@pwiz/priority/ts';
import { PriorityScoreIsInternalItemComponent } from '@pwiz/priority/impact/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatRadioModule } from '@angular/material/radio';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';

@Component({
  selector: 'pw-urgency-score-form',
  standalone: true,
  imports: [
    CommonModule,
    PwEnumToReadableStringPipe,
    ReactiveFormsModule,
    PriorityScoreIsInternalItemComponent,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    RadioButtonComponent,
    MatChipListbox,
    MatChipOption,
  ],
  templateUrl: './urgency-score-form.component.html',
  styleUrls: ['./urgency-score-form.component.scss'],
  /**
   *  Because it is a form component, on push doesn't work well for detecting form state changes
   *  TODO - revise this in angular 18
   * */
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [provideParentControl()],
})
export class UrgencyScoreFormComponent implements OnInit {
  #parentControlContainer =
    useParentControlContainer<FormGroup<UrgencyContainerForm>>();
  form = this.#initForm();
  $formValue = toSignal(this.form.valueChanges, {
    initialValue: this.form.value,
  });
  urgencyLevels = getUrgencyScores(URGENCY_LEVEL);
  $isReasonRequired = this.#requireReason();

  ngOnInit(): void {
    this.#parentControlContainer.control.setControl('urgencyScore', this.form);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<UrgencyFormGroup>({
      urgency: fb.control<UrgencyLevel | null>(null, [Validators.required]),
      isInternal: fb.control(false),
      reason: fb.control<string | null>(null),
    });
  }

  #requireReason() {
    return computed(() => {
      const { urgency } = this.$formValue();
      return (
        (urgency && URGENCIES_THAT_REQUIRE_REASON.includes(urgency)) || false
      );
    });
  }
}
