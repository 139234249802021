import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  input,
} from '@angular/core';
import { PwDrawerService } from '@pwiz/infra/drawer';
import { ItemSummaryDrawerComponent } from '@pwiz/item/summary/feature';
import { useDrawerRoute } from './use-drawer-route';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'pw-item-summary-sidebar-route',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<router-outlet />`,
  imports: [RouterOutlet],
})
export class ItemSummarySidebarRouteComponent {
  #drawer = inject(PwDrawerService);
  #injector = inject(Injector);
  itemId = input.required<string>();

  constructor() {
    useDrawerRoute(this.itemId, this.open.bind(this));
  }

  open(itemId: string) {
    this.#drawer.open(ItemSummaryDrawerComponent, this.#injector, {
      data: { itemId },
      name: 'Item Summary',
    });
  }
}
