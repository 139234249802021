import { computed, inject, Injectable, Signal } from '@angular/core';
import { getAssigneeFromUserAndSettings, UiPriority } from '@pwiz/priority/ts';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { isItemInternal } from '@pwiz/item/ts';
import { PrioritiesService } from './priorities.service';
import { useRunInInjectorContext } from '@pwiz/infra/hooks';
import { $useSettings } from '@pwiz/settings/ui-data';
import { $useProviderUsersAll } from '@pwiz/provider-user/ui-data';
import { ItemService } from '@pwiz/item/ui-data';
import { switchMap } from 'rxjs';

@Injectable()
export class PriorityHelperService {
  #priorityService = inject(PrioritiesService);
  #runWithInjector = useRunInInjectorContext();
  #itemService = inject(ItemService);

  $priorityLead($priority: Signal<UiPriority>) {
    const $settings = $useSettings();
    const $users = $useProviderUsersAll();
    return computed(() => {
      const settings = $settings();
      return getAssigneeFromUserAndSettings(
        $priority().providerAssigneeId,
        $priority().type,
        { settings, users: $users() || [] },
      );
    });
  }

  getIsInternalItem(priority: UiPriority) {
    return isItemInternal(priority.item);
  }

  $getPriority(priority: UiPriority) {
    return this.#runWithInjector(() =>
      toSignal(this.#priorityService.getPriority(priority.id), {
        initialValue: priority,
      }),
    );
  }

  $getItem($priority: Signal<UiPriority>) {
    const priority$ = toObservable($priority);
    return priority$.pipe(
      switchMap((priority) =>
        this.#itemService.getItemFullItemById(priority.item.id),
      ),
    );
  }
}
