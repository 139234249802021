@if ($descriptionText(); as text) {
  <p>{{text}}</p>
} @else {
  @for (content of $descriptionObject()?.content; track content) {
    @switch (content.type) {
      @case ('paragraph') {
        <p>
          @for (inner of content.content; track inner) {
            <pw-jira-description-text [content]="inner"/>
          }
        </p>
      }
      @case ('orderedList') {
        <ol>
          @for (inner of content.content; track inner) {
            @if (inner.type === 'listItem') {
              <li>
               <pw-jira-description-list [li]="inner"/>
              </li>
            }
          }
        </ol>
      }
      @case ('bulletList') {
        <ul class="raw">
          @for (inner of content.content; track inner) {
            <li>
              <pw-jira-description-list [li]="inner"/>
            </li>
          }
        </ul>
      }
    }
  } @empty {
    -
  }
}
