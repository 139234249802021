import { PwFormData } from '@pwiz/infra/form';
import { UrgencyScoreFormValue } from '../type';
import { UrgencyLevel } from '@pwiz/priority/ts';

const WARNING_LEVELS: UrgencyLevel[] = ['immediate_fix'];
export function showUrgencyFormWarning(
  formValue: PwFormData<UrgencyScoreFormValue> | null,
): boolean {
  if (!formValue) {
    return false;
  }
  if (!formValue.urgency) {
    return false;
  }
  return WARNING_LEVELS.includes(formValue.urgency);
}
