<ul>
  <li>
    <a routerLink="dashboard" routerLinkActive="active">
      <pw-sidebar-link pageName="Performance">
        <i slot="icon" class="tim-icons icon-spaceship"></i>
      </pw-sidebar-link>
    </a>
  </li>
  <li>
    <a routerLink="items" routerLinkActive="active">
      <pw-sidebar-link pageName="Items">
        <i slot="icon" class="tim-icons icon-atom"></i>
      </pw-sidebar-link>
    </a>
  </li>
  <li>
    <a routerLink="priorities/new" routerLinkActive="active">
      <pw-sidebar-link pageName="New Priorities">
        <i slot="icon" class="tim-icons icon-molecule-40"></i>
        <ng-content select="[slot=new-priorities-badge]" />
      </pw-sidebar-link>
    </a>
  </li>
  <li>
    <a routerLink="priorities/history" routerLinkActive="active">
      <pw-sidebar-link pageName="Priorities Archive">
        <i slot="icon" class="tim-icons icon-single-copy-04"></i>
      </pw-sidebar-link>
    </a>
  </li>
  <li>
    <a routerLink="sprint" routerLinkActive="active">
      <pw-sidebar-link pageName="Sprints">
        <pw-sprint-icon slot="icon" />
      </pw-sidebar-link>
    </a>
  </li>
  <li>
    <a routerLink="strategy" routerLinkActive="active">
      <pw-sidebar-link pageName="Business goals">
        <mat-icon slot="icon">location_on</mat-icon>
      </pw-sidebar-link>
    </a>
  </li>
  @if (!isProd()) {
    <li>
      <a routerLink="roadmap" routerLinkActive="active">
        <pw-sidebar-link pageName="Roadmap">
          <pw-roadmap-icon slot="icon"/>
          <span class="pw-badge" slot="name">Coming soon</span>
        </pw-sidebar-link>
      </a>
    </li>
  } @else {
    <li>
      <p>
        <pw-sidebar-link pageName="Roadmap">
          <pw-roadmap-icon slot="icon"/>
          <span class="pw-badge" slot="name">Coming soon</span>
        </pw-sidebar-link>
      </p>
    </li>
  }
  <li>
    <a routerLink="customer-lifecycle" routerLinkActive="active">
      <pw-sidebar-link pageName="Customer lifecycle">
        <mat-icon slot="icon"> support_agent </mat-icon>
      </pw-sidebar-link>
    </a>
  </li>
</ul>
