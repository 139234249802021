<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  (focus)="onFocus()"
  class="pw-flex pw-gap small-form-fields"
>
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Name</mat-label>
    <input type="text" matInput [formControl]="form.controls.name" />
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Link</mat-label>
    <input type="text" matInput [formControl]="form.controls.link" />
  </mat-form-field>
  <button mat-button type="submit">
    <mat-icon>add</mat-icon>
    Add
  </button>
</form>
<div class="link-list-container pw-flex pw-column pw-gap">
  @for (link of $value(); track link; let i = $index) {
    <div class="pw-flex pw-center-vr pw-space-between">
      <a [href]="link.link">{{ link.name }}</a>
      <button mat-button (mousedown)="removeLink(i)">
        <mat-icon>delete</mat-icon>
        Remove
      </button>
    </div>
  }
</div>
