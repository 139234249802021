import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PwDrawerService } from '../pw-drawer.service';

@Component({
  selector: 'pw-drawer',
  standalone: true,
  imports: [CommonModule, MatSidenavModule],
  templateUrl: './pw-drawer.component.html',
  styleUrl: './pw-drawer.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerComponent {
  #pwDrawer = inject(PwDrawerService);
  $component = this.#pwDrawer.$drawer;
}
