@for (account of accountList(); track account.id) {
  @if (account.name) {
    <span class="chip" [matTooltip]="account.arr | pwShortCurrency">{{account.name}}</span>
  }
}

<!--<mat-chip-set>-->
<!--  @for (account of accountList(); track account.id) {-->
<!--    @if (account.name) {-->
<!--      <mat-chip [matTooltip]="account.arr | pwShortCurrency" color="accent" highlighted>{{account.name}}</mat-chip>-->
<!--    }-->
<!--  }-->
<!--</mat-chip-set>-->
