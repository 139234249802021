import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicSelectSearchComponent } from '@pwiz/base/ui';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PwEnumToReadableStringPipe } from '@pwiz/infra/ui';
import { ITicketTableFilterFC, ITicketTableFilterFG } from '@pwiz/ticket/ts';
import { ITEM_PRIORITIES, ITEM_TYPES } from '@pwiz/item/ts';
import { IdValuePair } from '@pwiz/infra/ts';
import { eItemPriority } from '@pwiz/entity/ts';
import { useParentControlContainer } from '@pwiz/infra/form-2/ui';
import { ITEM_STATUSES } from '@pwiz/shared/ts';
import { ItemTypeIconComponent } from '@pwiz/item-common/ui';

@Component({
  selector: 'pw-ticket-table-filter',
  standalone: true,
  imports: [
    CommonModule,
    BasicSelectSearchComponent,
    FormsModule,
    ItemTypeIconComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    PwEnumToReadableStringPipe,
    ReactiveFormsModule,
  ],
  templateUrl: './ticket-table-filter.component.html',
  styleUrl: './ticket-table-filter.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTableFilterComponent implements OnInit {
  #parentForm = useParentControlContainer<FormGroup<ITicketTableFilterFC>>();
  form = useForm();
  itemTypes = ITEM_TYPES;
  itemPriorities = ITEM_PRIORITIES;
  statuses = ITEM_STATUSES;

  users = input.required<IdValuePair[]>();
  ngOnInit(): void {
    this.#parentForm.control.setControl('filter', this.form);
  }
}

function useForm() {
  const fb = inject(FormBuilder);
  return fb.group<ITicketTableFilterFG>({
    assignee: fb.control<string[]>([]),
    priority: fb.control<eItemPriority[]>([]),
    status: fb.control([]),
    type: fb.control(null),
  });
}
