import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  inject,
  input,
  OnInit,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  getAccountIdsFromImpactForm,
  getImpactLevelDisplayMap,
  getImpactMrrMap,
  hasImpactDealBreaker,
  ImpactFormGroup,
  ImpactFormGroupRow,
  ImpactForms,
  ImpactScoreForm,
  ItemImpactClientSelection,
} from '@pwiz/priority/impact/ts';
import { MatSelectModule } from '@angular/material/select';
import {
  CurrencyComponent,
  PwEnumToReadableStringPipe,
  PwOnInitSignal,
} from '@pwiz/infra/ui';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { ItemImpactScoreConnectCrmMessageComponent } from '../item-impact-score-connect-crm-message/item-impact-score-connect-crm-message.component';
import { CONNECT_CRM_MESSAGE } from '../consts/connect-crm-message';
import { toSignal } from '@angular/core/rxjs-interop';
import { IMPACT_LEVELS } from '@pwiz/priority/ts';
import { PriorityScoreIsInternalItemComponent } from '../priority-score-is-internal-item/priority-score-is-internal-item.component';
import {
  $getFormValue,
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import { eImpactLevel } from '@pwiz/entity/ts';
import { BasicSelectSearchComponent } from '@pwiz/base/ui';

@Component({
  selector: 'pw-item-impact-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    PwEnumToReadableStringPipe,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    ItemImpactScoreConnectCrmMessageComponent,
    PriorityScoreIsInternalItemComponent,
    CurrencyComponent,
    BasicSelectSearchComponent,
  ],
  templateUrl: './impact-score-form.component.html',
  styleUrls: ['./impact-score-form.component.scss'],
  /**
   *  Because it is a form component, on push doesn't work well for detecting form state changes
   *  TODO - revise this in angular 18
   * */
  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [provideParentControl()],
})
export class ImpactScoreFormComponent extends PwOnInitSignal implements OnInit {
  #fb = inject(FormBuilder);
  #parentControlContainer = useParentControlContainer<FormGroup>();

  connectCrmMessage = CONNECT_CRM_MESSAGE;
  name = 'impactScore';

  form = this.#initForm();

  $formValueSignal = toSignal(this.form.valueChanges, {
    initialValue: this.form.value,
  });

  clientListMap = this.#getClientListMap();

  $isReasonRequired = this.#getIsReasonRequired();

  $impactLevelDisplayMap = computed(() => {
    return getImpactLevelDisplayMap(
      this.$didInit() ? this.$isFeature() : false,
    );
  });

  $formValue = $getFormValue(this.form);
  $impactMap = computed(() => this.$formValue().clientsImpact);
  $impactArr = computed(() => {
    if (!this.$didInit()) {
      return {};
    }
    return getImpactMrrMap(this.$impactMap() as ImpactForms[], this.$clients());
  });

  @HostBinding('class.disabled-labels')
  disabledLabels = false;

  $clients = input.required<ItemImpactClientSelection[]>({ alias: 'clients' });

  $isFeature = input.required<boolean>({ alias: 'isFeature' });

  $ratedWithNoCustomers = input.required<boolean>({
    alias: 'ratedWithNoCustomers',
  });

  $initialImpactScore = input.required<eImpactLevel | null>({
    alias: 'initialImpactScore',
  });

  constructor() {
    super();
    this.#initFormChecks();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.#parentControlContainer.control.setControl('impactScore', this.form);
  }

  #initFormChecks() {
    effect(
      () => {
        if (!this.$didInit()) {
          return;
        }
        this.disabledLabels = false;
        this.form.controls.clientsImpact.enable();
        return;
      },
      { allowSignalWrites: true },
    );
  }

  #getClientListMap() {
    const clientsSignal = computed(() => {
      if (!this.$didInit()) {
        return [];
      }
      return this.$clients();
    });
    return IMPACT_LEVELS.reduce(
      (acc, impactLevel) => {
        return {
          ...acc,
          [impactLevel]: this.#filterSelectedClients(
            clientsSignal,
            impactLevel,
          ),
        };
      },
      {} as Record<eImpactLevel, Signal<ItemImpactClientSelection[]>>,
    );
  }

  #filterSelectedClients(
    clients$: Signal<ItemImpactClientSelection[]>,
    impactLevel: eImpactLevel,
  ): Signal<ItemImpactClientSelection[]> {
    return computed(() => {
      const { clientsImpact } = this.$formValueSignal();
      const allClients = clients$();
      const allOtherRows = clientsImpact?.filter(
        (row) => row?.impact !== impactLevel,
      );
      const selectedClients = getAccountIdsFromImpactForm(allOtherRows);
      // remove selectedClients from all clients
      return allClients.filter(
        (client) => !selectedClients.includes(client.id),
      );
    });
  }

  #initForm() {
    return this.#fb.group<ImpactFormGroup>({
      reason: this.#fb.control<string | null>(null),
      clientsImpact: this.#createClientImpactControl(IMPACT_LEVELS),
    });
  }

  #createClientImpactControl(
    array: eImpactLevel[] | Readonly<eImpactLevel[]> = IMPACT_LEVELS,
  ) {
    return this.#fb.array<ImpactFormGroupRow>(this.#adjustForm(array), [
      this.#customValidator(),
    ]);
  }

  #getRow(impactLevel: eImpactLevel): ImpactFormGroupRow {
    return this.#fb.group({
      impact: this.#fb.control(impactLevel, [Validators.required]),
      customerIds: this.#fb.control<string[]>([]),
    });
  }

  #customValidator(): ValidatorFn {
    return (
      arr: AbstractControl<ImpactForms[]>,
    ): { [key: string]: unknown } | null => {
      const hasCustomer = arr.value.some((row) => row.customerIds?.length > 0);
      if (hasCustomer) {
        return null;
      }
      return {
        errorCode: 'no_customer',
        text: 'At least one customer is required',
      };
    };
  }

  #adjustForm(array: eImpactLevel[] | Readonly<eImpactLevel[]>) {
    return array.map((impactLevel) => {
      return this.#getRow(impactLevel);
    });
  }

  #getIsReasonRequired() {
    const formValueSignal = toSignal(this.form.valueChanges);
    return computed(() =>
      hasImpactDealBreaker(formValueSignal() as ImpactScoreForm),
    );
  }
}
