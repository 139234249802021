import { Route } from '@angular/router';

export const DASHBOARD_ROUTES: Route[] = [
  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard-layout.component'),
    children: [
      {
        path: 'bug',
        loadComponent: () => import('./bug/page/bug-dashboard-page.component'),
      },
      {
        path: 'feature',
        loadComponent: () =>
          import('./page/feature/feature-dashboard-page.component'),
      },
      {
        path: 'custom',
        loadComponent: () =>
          import('./page/custom/custom-chart-page.component'),
      },
      {
        path: '',
        redirectTo: 'bug',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'bug',
        pathMatch: 'full',
      },
    ],
  },
];
