import { Pipe, PipeTransform } from '@angular/core';
import { IPriority } from '@pwiz/entity/ts';
import { isPriorityAutoRated } from '@pwiz/priority/ts';

@Pipe({
  name: 'pwPriorityAutoRated',
  standalone: true
})

export class PriorityAutoRatedPipe implements PipeTransform {
  transform(priority: IPriority): boolean {
    return isPriorityAutoRated(priority);
  }
}
