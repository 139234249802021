import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
} from '@angular/core';
import {
  ePriorityStatus,
  getItemTicketBase,
  isItemPrioritized,
} from '@pwiz/entity/ts';
import {
  CurrencyComponent,
  PwCurrencyPipe,
  PwDateTimePipe,
  PwEnumToReadableStringPipe,
  PwLabelComponent,
  PwLabelGroupComponent,
  PwLabelHeaderComponent,
  PwNullableValuePipe,
  PwShortCurrencyPipe,
  useHostBinding,
} from '@pwiz/infra/ui';
import { NgIf } from '@angular/common';
import { UiPriority } from '@pwiz/priority/ts';
import { FindUserPipe } from '@pwiz/user/ui';
import { findUser, PwUser } from '@pwiz/user/ts';
import { ItemIdComponent } from '@pwiz/item-common/ui';

@Component({
  standalone: true,
  selector: 'pw-priority-summary',
  templateUrl: './priority-summary.component.html',
  styleUrls: ['./priority-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: '',
  },
  imports: [
    PwEnumToReadableStringPipe,
    PwDateTimePipe,
    PwNullableValuePipe,
    NgIf,
    FindUserPipe,
    PwLabelGroupComponent,
    PwLabelComponent,
    PwLabelHeaderComponent,
    PwCurrencyPipe,
    PwShortCurrencyPipe,
    CurrencyComponent,
    ItemIdComponent,
  ],
})
export class PrioritySummaryComponent {
  users = input.required<PwUser[] | null>();
  priority = input.required<UiPriority>();
  priorityLead = input<string>();
  design = input<1 | 2>(1);

  $isScored = computed(() => {
    const { status } = this.priority();
    return ![ePriorityStatus.skipped, ePriorityStatus.new].includes(status);
  });

  $scoredBy = computed(() => {
    const { scoredBy, status } = this.priority();
    if (status === ePriorityStatus.autoRated) {
      return 'PWiZ automation';
    }
    return findUser(scoredBy, this.users())?.name || null;
  });

  $isPrioritized = computed(() => isItemPrioritized(this.priority().item));

  $ticketBase = computed(() => {
    const item = this.priority()?.item;
    if (!item) {
      return null;
    }
    return getItemTicketBase(item);
  });

  constructor() {
    const { conditionalClass } = useHostBinding();
    effect(() => conditionalClass('design-v2', this.design() === 2));
    effect(() => conditionalClass('pw-card', this.design() === 1));
  }
}
