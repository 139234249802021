import { Route } from '@angular/router';
import { Provider } from '@angular/core';
import { ITEM_PAGE_URL } from '@pwiz/item/ts';

/**
 *
 * @param providers - ItemPageEventAdapter
 * @constructor
 */
export const ITEMS_ROUTE = (): Route => ({
  path: ITEM_PAGE_URL,
  loadComponent: () => import('./items-page/items-page.component'),
});
