import { computed, inject, Injectable, Signal } from '@angular/core';
import { AccountsService } from '@pwiz/account/ui-data';
import { map, shareReplay, tap } from 'rxjs';
import {
  ImpactScoreForm,
  ItemImpactClientSelection,
  mapImpactFormToRequest,
  mapPriorityToImpactScoreForm,
} from '@pwiz/priority/impact/ts';
import {
  PrioritiesService,
  PriorityHelperService,
} from '@pwiz/priority/ui-data';
import { eAccountStatus, eItemType, IPriority } from '@pwiz/entity/ts';
import { toSignal } from '@angular/core/rxjs-interop';
import { IPriorityResponse, UiPriority } from '@pwiz/priority/ts';
import { HttpClient } from '@angular/common/http';
import { useBaseApiUrl } from '@pwiz/infra/environment';
import { ProviderService } from '@pwiz/provider/ui-data';
import { filterAccountsByStats } from '@pwiz/account/ts';

@Injectable()
export class PriorityImpactService {
  #http = inject(HttpClient);
  #api = useBaseApiUrl('priority');

  #accountService = inject(AccountsService);
  #priorityService = inject(PrioritiesService);
  #providerService = inject(ProviderService);
  #priorityHelperService = inject(PriorityHelperService);

  getItemImpactValues({ id }: UiPriority) {
    return this.#priorityService
      .getPriority(id)
      .pipe(map(mapPriorityToImpactScoreForm), shareReplay(1));
  }

  getIsInternalItem(priority: UiPriority) {
    return this.#priorityHelperService.getIsInternalItem(priority);
  }

  accounts(): Signal<ItemImpactClientSelection[]> {
    const accounts$ = toSignal(this.#accountService.getAccounts$(), {
      requireSync: true,
    });
    return computed(() => {
      const { data: accounts } = accounts$();
      if (!accounts) {
        return [];
      }
      return (
        filterAccountsByStats(accounts, [eAccountStatus.customer])?.map(
          (account) => ({
            id: account.id,
            value: account.name,
            monthlyRecurringRevenue: account.arr,
          }),
        ) || []
      );
    });
  }

  saveImpactScore(priority: IPriority, impact: ImpactScoreForm) {
    return this.#priorityService.updateImpact(
      priority.id,
      mapImpactFormToRequest(impact, priority.item?.type === eItemType.feature),
    );
  }

  hasCrmConnected() {
    return toSignal(this.#providerService.hasCrmConnected$(), {
      initialValue: true,
    });
  }

  skipImpact({ id }: UiPriority) {
    return this.#http
      .put<IPriorityResponse>(`${this.#api}/skip-impact`, { priorityId: id })
      .pipe(tap((res) => this.#priorityService.updateCache(res)));
  }
}
