import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DrawerRoutingService,
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerHeaderComponent,
  usePwDrawerData,
} from '@pwiz/infra/drawer';
import { TicketSummaryUiComponent } from '@pwiz/ticket/ui';
import { $useSettingsSafe } from '@pwiz/settings/ui-data';
import { TicketService } from '@pwiz/ticket/ui-data';
import { toSignal } from '@angular/core/rxjs-interop';
import { ITicket } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-ticket-summary',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    PwDrawerContentComponent,
    PwDrawerHeaderComponent,
    TicketSummaryUiComponent,
  ],
  templateUrl: './ticket-summary.component.html',
  styleUrl: './ticket-summary.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketSummaryComponent {
  #itemSummary = inject(DrawerRoutingService);
  #ticketService = inject(TicketService);
  ticketId = usePwDrawerData<{ ticketId: string }>().ticketId;
  $ticket = toSignal(this.getTicket(), { initialValue: null });
  $settings = $useSettingsSafe();

  getTicket() {
    return this.#ticketService.getTicket(this.ticketId);
  }

  onParentClick({ ticket: { item, id, epic } }: { ticket: ITicket }) {
    const ticketItem = item || epic?.item;
    if (ticketItem?.id) {
      this.#itemSummary.itemSummary(ticketItem.id);
    } else {
      throw new Error(`Item has no id for ticket ${id}`);
    }
  }
}
