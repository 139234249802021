import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pwRound',
  standalone: true,
})
export class PwRoundPipe implements PipeTransform {
  transform(value?: number | null): number | null {
    return value == null ? null : Math.round(value);
  }
}
