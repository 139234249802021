import { Pipe, PipeTransform } from '@angular/core';
import { IAccount } from '@pwiz/entity/ts';
import { getArrSum } from '@pwiz/account/ts';

@Pipe({
  name: 'pwSumArr',
  standalone: true,
})
export class SumArrPipe implements PipeTransform {
  transform(accountList?: IAccount[] | null) {
    return accountList ? getArrSum(accountList) : 0;
  }
}
