import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { OrganizationService } from '@pwiz/organization/ui-data';
import { CurrentUserService } from '@pwiz/user/ui-data';
import { combineLatest, filter, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'pwiz-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxSpinnerComponent, RouterOutlet],
})
export class AppComponent {
  #authService = inject(AuthService);
  #orgService = inject(OrganizationService);
  #currentUser = inject(CurrentUserService);
  #spinner = inject(NgxSpinnerService);
  //
  constructor() {
    this.#spinner.show();
    this.#authService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() =>
          combineLatest([
            this.#orgService.organization$,
            this.#currentUser.user$(),
          ]),
        ),
        take(1),
      )
      .subscribe(() => this.#spinner.hide());
  }
}
