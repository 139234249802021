import { Component, effect, input, ViewEncapsulation } from '@angular/core';
import { PwDialogSize } from '../../dialog-config.type';
import { getModalSize } from '../../pw-dialog.service';
import { useHostBinding } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-dialog-v3',
  standalone: true,
  styleUrl: 'dialog-v3.component.scss',
  host: { class: 'pw-dialog-v3 pw-dialog-v2 pw-scroll-container' },
  template: ` <ng-content /> `,
  encapsulation: ViewEncapsulation.None,
})
export class DialogV3Component {
  size = input<string, PwDialogSize>('m', {
    transform: getModalSize,
  });
  constructor() {
    const { setStyles } = useHostBinding();
    effect(() => setStyles('width', this.size()));
  }
}
