import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pwShortNumber',
  standalone: true,
})
export class ShortNumberPipe implements PipeTransform {
  powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1_000 },
  ];

  transform(number?: number | null) {
    if (number == null) return null;
    if (isNaN(number)) return null; // will only work value is a number
    if (number === 0) return 0;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0; // will also work for Negative numbers
    let key = '';

    for (let i = 0; i < this.powers.length; i++) {
      let reduced = abs / this.powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = this.powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }
}
