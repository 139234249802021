import { Route } from '@angular/router';
import { provideContentProjection } from '@pwiz/infra/content-projection/feature';
import { SprintPageComponent } from './sprint-page/sprint-page.component';

export const SPRINT_LAYOUT_ROUTE: Route = {
  path: 'sprint',
  loadComponent: () => import('./sprint-layout/sprint-layout.component'),
  children: [
    {
      path: 'planing',
      loadComponent: () =>
        import('./sprint-page-container/sprint-page-container.component'),
      providers: provideContentProjection(),
      children: [
        {
          path: 'auto',
          component: SprintPageComponent,
        },
        {
          path: 'committed',
          component: SprintPageComponent,
        },
        {
          path: '',
          redirectTo: 'auto',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: ':sprintId',
      loadComponent: () =>
        import(
          './individual-sprint-page-container/individual-sprint-page-container.component'
        ),
      children: [
        {
          path: '',
          loadComponent: () =>
            import('./sprint-details-page/sprint-details-page.component'),
          children: [
            {
              path: 'feature',
              loadComponent: () =>
                import(
                  './sprint-details-page/components/table/sprint-detail-item-view.component'
                ),
            },
            {
              path: 'ticket',
              loadComponent: () =>
                import(
                  './sprint-details-page/components/table/sprint-detail-ticket-view.component'
                ),
            },
            {
              path: 'epic',
              loadComponent: () =>
                import(
                  './sprint-details-page/components/table/sprint-detail-epic-view.component'
                ),
            },
            {
              path: '',
              redirectTo: 'feature',
              pathMatch: 'full',
            },
            {
              path: '**',
              redirectTo: 'feature',
              pathMatch: 'full',
            },
          ],
        },
      ],
    },
    {
      path: '**',
      redirectTo: 'planing',
      pathMatch: 'full',
    },
  ],
};
