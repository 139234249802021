import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPriority } from '@pwiz/entity/ts';
import {
  PwEnumToReadableStringPipe,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { getItemIssue } from '@pwiz/item/ts';
import { ItemDescriptionComponent } from '@pwiz/jira/ui';

@Component({
  selector: 'pw-priority-score-dialog-header',
  standalone: true,
  imports: [
    CommonModule,
    PwNullableValuePipe,
    PwEnumToReadableStringPipe,
    ItemDescriptionComponent,
  ],
  templateUrl: './priority-score-dialog-header.component.html',
  styleUrls: ['./priority-score-dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-flex pw-column pw-gap-l',
  },
})
export class PriorityScoreDialogHeaderComponent {
  priority = input.required<IPriority>();
  issue = computed(() => {
    const item = this.priority()?.item;
    return item ? getItemIssue(item) : null;
  });
}
