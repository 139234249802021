import { Route } from '@angular/router';

export const CUSTOMER_LIFE_CYCLE_ROUTE: Route = {
  path: 'customer-lifecycle',
  loadComponent: () =>
    import('./customer-life-cycle-layout/customer-life-cycle-layout.component'),
  children: [
    {
      path: ':id',
      loadComponent: () =>
        import('./customer-items-page/customer-items-page.component'),
    },
  ],
};
