import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IJiraInnerContent } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-jira-description-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './jira-description-text.component.html',
  styleUrl: './jira-description-text.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JiraDescriptionTextComponent {
  @Input({ required: true })
  content!: IJiraInnerContent;
}
