import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  EffortTimeMap,
  getEffortLevelOptions,
  PriorityEffortFormContainer,
  PriorityEffortFormGroup,
} from '@pwiz/priority/effort/ts';
import {
  PwEnumToReadableStringPipe,
  RadioButtonComponent,
} from '@pwiz/infra/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import {
  provideParentControl,
  useParentControlContainer,
} from '@pwiz/infra/form-2/ui';
import { IdValuePair } from '@pwiz/infra/ts';
import { eEffortLevel } from '@pwiz/entity/ts';
import {
  MatChip,
  MatChipListbox,
  MatChipOption,
} from '@angular/material/chips';

@Component({
  selector: 'pw-priority-effort-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PwEnumToReadableStringPipe,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    RadioButtonComponent,
    MatChipListbox,
    MatChip,
    MatChipOption,
  ],
  templateUrl: './priority-effort-form.component.html',
  styleUrls: ['./priority-effort-form.component.scss'],
  /**
   *  Because it is a form component, on push doesn't work well for detecting form state changes
   *  TODO - revise this in angular 17
   * */

  changeDetection: ChangeDetectionStrategy.Default,
  viewProviders: [provideParentControl()],
})
export class PriorityEffortFormComponent implements OnInit {
  #parentControl =
    useParentControlContainer<FormGroup<PriorityEffortFormContainer>>();
  effortScores: IdValuePair<number>[] = getEffortLevelOptions();
  form = this.#initForm();
  name = 'effortScore';

  effortTimeMap$ = input<EffortTimeMap | null | undefined>();

  ngOnInit(): void {
    this.#parentControl.control.setControl('effortScore', this.form);
  }

  #initForm() {
    const fb = inject(FormBuilder);
    return fb.group<PriorityEffortFormGroup>({
      effort: fb.control<eEffortLevel | null>(null, [Validators.required]),
      reason: fb.control<string | null>(null),
    });
  }
}
