import { computed, inject, Injectable } from '@angular/core';
import {
  PrioritiesService,
  PriorityHelperService,
} from '@pwiz/priority/ui-data';
import {
  mapEffortFormToPriority,
  PriorityEffortFormValue,
} from '@pwiz/priority/effort/ts';
import { map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { PwFormData } from '@pwiz/infra/form';
import { UiPriority } from '@pwiz/priority/ts';
import { BaseEntityUtils } from '@pwiz/entity/ts';
import { $useSettings, SettingsService } from '@pwiz/settings/ui-data';
import { getEffortMapFromSettings } from '@pwiz/settings/ts';

@Injectable()
export class PriorityEffortService {
  #priorityService = inject(PrioritiesService);
  #priorityHelperService = inject(PriorityHelperService);
  #settingsService = inject(SettingsService);

  getAssignee$() {
    const settings = $useSettings();
    return computed(() => {
      return {
        priorityLead: settings().effortProviderAssignee?.name || '',
      };
    });
  }

  getEffortTimes() {
    return toSignal(
      this.#settingsService.getSettings_unsafe$().pipe(
        map((settings) => {
          return {
            bug: getEffortMapFromSettings(false, settings),
            feature: getEffortMapFromSettings(true, settings),
          };
        }),
      ),
    );
  }

  getAverageEngineerCost$() {
    return toSignal(
      this.#settingsService
        .getSettings_unsafe$()
        .pipe(map((settings) => settings?.averageEngineerCost || 0)),
    );
  }

  updatePriority(
    { id }: UiPriority,
    effortFormValue: PwFormData<PriorityEffortFormValue>,
  ) {
    const { data } = this.#priorityService.peek();
    const priority = BaseEntityUtils.findEntityById(data || [], id);
    if (!priority) {
      throw new Error('No priority in cache');
    }
    return this.#priorityService.updateScore({
      ...priority,
      ...mapEffortFormToPriority(effortFormValue),
    });
  }

  isInternal(priority: UiPriority) {
    return this.#priorityHelperService.getIsInternalItem(priority);
  }

  $getPriority(priority: UiPriority) {
    return this.#priorityHelperService.$getPriority(priority);
  }
}
