import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  Signal,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DialogContentComponent,
  DialogFooterComponent,
  DialogFooterDirective,
  DialogHeaderComponent,
  DialogV2Component,
  useCloseDialogFinished,
  useDialogData,
} from '@pwiz/infra/dialog';
import { PriorityScoreDialogData, UiPriority } from '@pwiz/priority/ts';
import {
  ImpactViewComponent,
  PriorityAutoRatedPipe,
  PriorityScoreDialogContainerComponent,
  PriorityScoreDialogHeaderComponent,
  PriorityScoreFormContainerComponent,
  PriorityScoreFormContainerDirective,
  PriorityScoreItemSummeryContainerDirective,
  PriorityScoreSubmitContainerComponent,
  PrioritySummaryComponent,
} from '@pwiz/priority/ui';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrgencyScoreFormComponent } from '@pwiz/priority/urgency/ui';
import { MatButtonModule } from '@angular/material/button';
import { UrgencyScoreService } from '@pwiz/priority/urgency/ui-data';
import { PriorityHelperService } from '@pwiz/priority/ui-data';
import { PwFormData } from '@pwiz/infra/form';
import {
  showUrgencyFormWarning,
  toUrgencyScoreForm,
  UrgencyContainerForm,
  UrgencyScoreFormValue,
} from '@pwiz/priority/urgency/ts';
import { PwAfterViewInit$ } from '@pwiz/infra/ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { useRunInInjectorContext } from '@pwiz/infra/hooks';
import { $useUserList } from '@pwiz/user/ui-data';
import { UiItemWithPriorities } from '@pwiz/item/ts';

@Component({
  selector: 'pw-urgency-score-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PriorityScoreDialogContainerComponent,
    PriorityScoreItemSummeryContainerDirective,
    PrioritySummaryComponent,
    PriorityScoreDialogHeaderComponent,
    FormsModule,
    UrgencyScoreFormComponent,
    PriorityScoreSubmitContainerComponent,
    MatButtonModule,
    PriorityScoreFormContainerDirective,
    PriorityScoreFormContainerComponent,
    ReactiveFormsModule,
    PriorityAutoRatedPipe,
    ImpactViewComponent,
    DialogV2Component,
    DialogHeaderComponent,
    DialogFooterComponent,
    DialogContentComponent,
    DialogFooterDirective,
  ],
  templateUrl: './urgency-score-dialog.component.html',
  styleUrls: ['./urgency-score-dialog.component.scss'],
  providers: [UrgencyScoreService, PriorityHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrgencyScoreDialogComponent
  extends PwAfterViewInit$
  implements AfterViewInit
{
  #cd = inject(ChangeDetectorRef);
  #dialogFinished = useCloseDialogFinished();
  #urgencyScoreService = inject(UrgencyScoreService);
  #priorityHelper = inject(PriorityHelperService);
  #partialPriority = useDialogData<PriorityScoreDialogData>().priority;
  #formValue = toSignal(
    this.afterViewInit$(() => this.form.controls.urgencyScore.valueChanges),
    { initialValue: null },
  );

  form = new FormGroup<UrgencyContainerForm>({} as UrgencyContainerForm);
  saving = signal(false);
  $priority = this.#urgencyScoreService.$getPriority(this.#partialPriority);
  $itemSummary: Signal<UiItemWithPriorities | null> = toSignal(
    this.#priorityHelper.$getItem(this.$priority),
    { initialValue: null },
  );
  assignees = this.#priorityHelper.$priorityLead(this.$priority);
  showWarning = computed(() => showUrgencyFormWarning(this.#formValue()));
  $users = $useUserList();

  #runInInjectorContext = useRunInInjectorContext();
  constructor() {
    super();
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.#runInInjectorContext(() =>
      effect(
        () => {
          const pri = this.$priority();
          if (pri) {
            this.form.controls.urgencyScore.patchValue(toUrgencyScoreForm(pri));
          }
        },
        { allowSignalWrites: true },
      ),
    );
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.#cd.detectChanges();
      return;
    }
    this.saving.set(true);
    this.#urgencyScoreService
      .updateScore(
        this.$priority() as UiPriority,
        this.form.value.urgencyScore as PwFormData<UrgencyScoreFormValue>,
      )
      .subscribe(() => this.#dialogFinished({ ok: true }));
  }
}
