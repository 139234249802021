import { Route } from '@angular/router';
import { ItemSummarySidebarRouteComponent } from './component/item-summary-sidebar-route.component';
import { ItemNestedTicketsSidebarRouterComponent } from './component/item-nested-tickets-sidebar-router.component';
import { TicketTableDataAdapter } from '@pwiz/ticket/ui-data';
import { TicketSummarySidebarRouteComponent } from './component/ticket-summary-sidebar-route.component';
import { ItemNestedTicketService } from '@pwiz/ticket/feature';
const routes: Route[] = [
  {
    path: 'item',
    providers: [
      {
        provide: TicketTableDataAdapter,
        useClass: ItemNestedTicketService,
      },
    ],
    children: [
      {
        path: ':itemId/tickets',
        component: ItemNestedTicketsSidebarRouterComponent,
      },
      {
        path: ':itemId',
        component: ItemSummarySidebarRouteComponent,
      },
    ],
  },
  {
    path: 'ticket/:ticketId',
    component: TicketSummarySidebarRouteComponent,
  },
  {
    path: 'sprint',
    children: [
      {
        path: ':sprintId/edit',
        loadComponent: () =>
          import('./component/sprint-edit-sidebar-route.component'),
      },
    ],
  },
  {
    path: 'strategy',
    children: [
      {
        path: 'business-goal/:businessGoalId',
        loadComponent: () =>
          import('./component/business-goal-drawer-route.component'),
      },
    ],
  },
  {
    path: 'team',
    children: [
      {
        path: ':teamId',
        loadComponent: () => 
          import('./component/team-summary-sidebar-route.component'),
      }
    ]
  }
];

export const SIDEBAR_ROUTES: Route[] = routes.map((route) => ({
  ...route,
  outlet: 'sidebar',
}));
