import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarLinkComponent } from './components/sidebar-link/sidebar-link.component';
import {
  RoadmapIconComponent,
  SprintIconComponent,
} from '@pwiz/infra/icons/ui';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'pw-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgOptimizedImage,
    SidebarLinkComponent,
    RouterLinkActive,
    SprintIconComponent,
    MatIconModule,
    RoadmapIconComponent,
    MatTooltip,
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  isProd = input.required<boolean>();
}
