import { IdValuePairExtra } from '@pwiz/infra/ts';
import { eConfidenceLevel } from '@pwiz/priority/ts';

const map: Record<
  eConfidenceLevel,
  Omit<IdValuePairExtra<number, string>, 'id'>
> = {
  [eConfidenceLevel.very_high]: { value: 'very high', extra: '100%' },
  [eConfidenceLevel.high]: { value: 'high', extra: '80%' },
  [eConfidenceLevel.medium]: { value: 'medium', extra: '60%' },
  [eConfidenceLevel.low]: { value: 'low', extra: '40%' },
  [eConfidenceLevel.very_low]: { value: 'very low', extra: '20%' },
};

export function getConfidenceScores(
  urgencyScores: Readonly<eConfidenceLevel[]>
): IdValuePairExtra<number, string>[] {
  return urgencyScores.map((urgencyScore) => ({
    id: urgencyScore,
    ...map[urgencyScore],
  }));
}
